// Imports for necessary libraries (React, useState, etc.)
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f4f4f4;
  }

  tr:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
`

export default function AstrologyTable({ birthChart, onRowClick }) {
  const [features, setFeatures] = useState([])

  useEffect(() => {
    if (birthChart) {
      const calculatedFeatures = []

      // Planetary Features
      Object.values(birthChart.objects).forEach((object) => {
        if (object.type.name === 'Planet') {
          calculatedFeatures.push({
            category: 'Planetary Features',
            feature: object.name,
            details: `${object.name} in ${object.sign.name} (House ${object.house.number})`,
          })
        }
      })

      // Asteroids & Points
      Object.values(birthChart.objects).forEach((object) => {
        if (object.type.name === 'Asteroid' || object.type.name === 'Point') {
          calculatedFeatures.push({
            category: 'Asteroids & Points',
            feature: object.name,
            details: `${object.name} in ${object.sign.name} (House ${object.house.number})`,
          })
        }
      })

      // Houses
      Object.values(birthChart.houses).forEach((house) => {
        calculatedFeatures.push({
          category: 'Houses',
          feature: `${house.number}th House`,
          details: `Cusp in ${house.sign.name}`,
        })
      })

      // Angles
      ;['Asc', 'Desc', 'MC', 'IC'].forEach((angle) => {
        const angleObject = Object.values(birthChart.objects).find((obj) => obj.name === angle)
        if (angleObject) {
          calculatedFeatures.push({
            category: 'Angles',
            feature: angle,
            details: `${angle} in ${angleObject.sign.name}`,
          })
        }
      })

      // Unique Highlights
      if (birthChart.moon_phase) {
        calculatedFeatures.push({
          category: 'Unique Highlights',
          feature: 'Moon Phase',
          details: birthChart.moon_phase.formatted,
        })
      }

      // Out-of-Bounds Planets
      Object.values(birthChart.objects).forEach((object) => {
        if (object.out_of_bounds) {
          calculatedFeatures.push({
            category: 'Unique Highlights',
            feature: 'Out-of-Bounds',
            details: `${object.name} is out of bounds`,
          })
        }
      })

      // Retrograde Planets
      Object.values(birthChart.objects).forEach((object) => {
        if (object.movement && object.movement.retrograde) {
          calculatedFeatures.push({
            category: 'Unique Highlights',
            feature: 'Retrograde',
            details: `${object.name} is retrograde`,
          })
        }
      })

      // Dominant Quadrant
      const quadrantCounts = Object.entries(birthChart.weightings.quadrants).map(
        ([quadrant, objects]) => ({
          quadrant,
          count: objects.length,
        }),
      )
      const dominantQuadrant = quadrantCounts.reduce((max, current) =>
        current.count > max.count ? current : max,
      )
      calculatedFeatures.push({
        category: 'Unique Highlights',
        feature: 'Dominant Quadrant',
        details: `${dominantQuadrant.quadrant} Quadrant has the most activity`,
      })

      setFeatures(calculatedFeatures)
    }
  }, [birthChart])

  return (
    <StyledTable>
      <thead>
        <tr>
          <th>Category</th>
          <th>Feature</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
        {features.map((feature, index) => (
          <tr key={index} onClick={() => onRowClick(`${feature.category} - ${feature.details}`)}>
            <td>{feature.category}</td>
            <td>{feature.feature}</td>
            <td>{feature.details}</td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}

AstrologyTable.propTypes = {
  birthChart: PropTypes.object.isRequired,
  onRowClick: PropTypes.func.isRequired,
}
