import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import CheckmarkCircleIcon from 'components/SVG/CheckmarkCircleIcon'
import BlockButton from 'components/buttons/BlockButton'
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_name.json'
import { useCoachingOptions } from 'constants/COACHING_OPTIONS'

const StyledCoachingAreasStep = styled.div``

const CoachingAreaOption = ({ isSelected, onSelect, label, icon }) => (
  <div
    className={`icon-select is-primary-button ${isSelected ? 'is-active' : ''}`}
    onClick={onSelect}
  >
    <div className="icon-select-left">
      <div className="icon-select-icon">{icon}</div>
      <span>{label}</span>
    </div>
    {isSelected && (
      <div className="icon-select-right">
        <CheckmarkCircleIcon />
      </div>
    )}
  </div>
)

export default function CoachingAreasStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  selectedCoachingAreas,
  setSelectedCoachingAreas,
}) {
  const { t } = useTranslation('common')
  const coachingOptions = useCoachingOptions()

  console.log(selectedCoachingAreas)

  const toggleArea = (area) => {
    if (selectedCoachingAreas.includes(area)) {
      setSelectedCoachingAreas(selectedCoachingAreas.filter((a) => a !== area))
    } else {
      setSelectedCoachingAreas([...selectedCoachingAreas, area])
    }
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <StyledCoachingAreasStep>
      <div className="animated fadeIn">
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>

      <span className="onboarding-headline animated fadeIn">
        {t('welcome-page.coaching-headline')}
      </span>
      <span className="onboarding-text animated fadeIn">{t('welcome-page.coaching-text')}</span>

      <div className="icon-select-wrapper animated fadeIn">
        {coachingOptions.map(({ value, label, icon }) => (
          <CoachingAreaOption
            key={value}
            isSelected={selectedCoachingAreas.includes(value)}
            onSelect={() => toggleArea(value)}
            label={label}
            icon={icon}
          />
        ))}
      </div>

      <p>&nbsp;</p>

      <p>&nbsp;</p>

      <BlockButton
        isBottom
        disabled={selectedCoachingAreas.length === 0}
        text={t('welcome-page.coaching-continue')}
        onClick={() => {
          scrollToWidget()
          setCurrentStep(currentStep + 1)
        }}
      />
    </StyledCoachingAreasStep>
  )
}
