// Function to determine dominant element
export const calculateDominantElement = (elements) => {
  const elementScores = Object.entries(elements).map(([key, value]) => ({
    element: key,
    score: value.length,
  }))

  const dominant = elementScores.reduce((max, current) =>
    current.score > max.score ? current : max,
  )
  return dominant.element.charAt(0).toUpperCase() + dominant.element.slice(1)
}

// Function to determine dominant modality
export const calculateDominantModality = (modalities) => {
  const modalityScores = Object.entries(modalities).map(([key, value]) => ({
    modality: key,
    score: value.length,
  }))

  const dominant = modalityScores.reduce((max, current) =>
    current.score > max.score ? current : max,
  )
  return dominant.modality.charAt(0).toUpperCase() + dominant.modality.slice(1)
}

// Function to determine moon phase
export const getMoonPhase = (moonPhaseData) => {
  return moonPhaseData.formatted
}

// Function to get Rising Sign (Ascendant)
export const getRisingSign = (objects) => {
  const ascendant = Object.values(objects).find((obj) => obj.name === 'Asc')
  return ascendant ? ascendant.sign.name : null
}

// Function to get Sun Sign
export const getSunSign = (objects) => {
  const sun = Object.values(objects).find((obj) => obj.name === 'Sun')
  return sun ? sun.sign.name : null
}

// Function to get Moon Sign
export const getMoonSign = (objects) => {
  const moon = Object.values(objects).find((obj) => obj.name === 'Moon')
  return moon ? moon.sign.name : null
}
