import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import CheckmarkCircleIcon from 'components/SVG/CheckmarkCircleIcon'
import BlockButton from 'components/buttons/BlockButton'
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_name.json'

const StyledCombinedCareerPreferencesStep = styled.div``

const Option = ({ isSelected, onSelect, label, icon }) => (
  <div
    className={`icon-select is-primary-button ${isSelected ? 'is-active' : ''}`}
    onClick={onSelect}
  >
    <div className="icon-select-left">
      <div className="icon-select-icon">{icon}</div>
      <span>{label}</span>
    </div>

    <div className="icon-select-right">
      <CheckmarkCircleIcon />
    </div>
  </div>
)

export default function CombinedCareerPreferencesStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  selectedCareerAnswers,
  setSelectedCareerAnswers,
}) {
  const { t } = useTranslation('common')
  const [currentQuestion, setCurrentQuestion] = useState(0)

  const questions = [
    /*{
      key: 'career-insights',
      headline: t('welcome-page.career-insights-headline'),
      text: t('welcome-page.career-insights-text'),
      options: [
        { value: 'dream-job', icon: '💼', label: t('welcome-page.career-insights-dream-job') },
        {
          value: 'financial-success',
          icon: '💰',
          label: t('welcome-page.career-insights-financial-success'),
        },
        { value: 'joyful-work', icon: '🎨', label: t('welcome-page.career-insights-joyful-work') },
        { value: 'creativity', icon: '🌟', label: t('welcome-page.career-insights-creativity') },
        { value: 'other', icon: '❓', label: t('welcome-page.career-insights-other') },
      ],
    },*/
    {
      key: 'birth-chart-analysis',
      headline: t('welcome-page.birth-chart-analysis-headline'),
      text: t('welcome-page.birth-chart-analysis-text'),
      options: [
        {
          value: 'personality-traits',
          icon: '🧠',
          label: t('welcome-page.birth-chart-analysis-personality-traits'),
        },
        {
          value: 'compatibility-guide',
          icon: '❤️',
          label: t('welcome-page.birth-chart-analysis-compatibility-guide'),
        },
        { value: 'life-path', icon: '🌍', label: t('welcome-page.birth-chart-analysis-life-path') },
        {
          value: 'sexual-compatibility',
          icon: '🔥',
          label: t('welcome-page.birth-chart-analysis-sexual-compatibility'),
        },
        {
          value: 'detailed-horoscopes',
          icon: '📅',
          label: t('welcome-page.birth-chart-analysis-detailed-horoscopes'),
        },
        {
          value: 'upcoming-challenges',
          icon: '⚠️',
          label: t('welcome-page.birth-chart-analysis-upcoming-challenges'),
        },
        { value: 'other', icon: '❓', label: t('welcome-page.birth-chart-analysis-other') },
      ],
    },
    /*{
      key: 'important-dates',
      headline: t('welcome-page.important-dates-headline'),
      text: t('welcome-page.important-dates-text'),
      options: [
        {
          value: 'perfect-match',
          icon: '💞',
          label: t('welcome-page.important-dates-perfect-match'),
        },
        {
          value: 'new-relationship',
          icon: '💑',
          label: t('welcome-page.important-dates-new-relationship'),
        },
        {
          value: 'wedding-date',
          icon: '💍',
          label: t('welcome-page.important-dates-wedding-date'),
        },
        { value: 'conceive', icon: '👶', label: t('welcome-page.important-dates-conceive') },
        {
          value: 'resolve-conflicts',
          icon: '🤝',
          label: t('welcome-page.important-dates-resolve-conflicts'),
        },
        {
          value: 'career-changes',
          icon: '📈',
          label: t('welcome-page.important-dates-career-changes'),
        },
        { value: 'other', icon: '❓', label: t('welcome-page.important-dates-other') },
      ],
    },*/
    {
      key: 'additional-topics',
      headline: t('welcome-page.additional-topics-headline'),
      text: t('welcome-page.additional-topics-text'),
      options: [
        { value: 'tarot', icon: '🔮', label: t('welcome-page.additional-topics-tarot') },
        { value: 'numerology', icon: '🔢', label: t('welcome-page.additional-topics-numerology') },
        {
          value: 'astrological-events',
          icon: '🌌',
          label: t('welcome-page.additional-topics-astrological-events'),
        },
        {
          value: 'palm-reading',
          icon: '✋',
          label: t('welcome-page.additional-topics-palm-reading'),
        },
        { value: 'crystals', icon: '💎', label: t('welcome-page.additional-topics-crystals') },
        { value: 'divination', icon: '📜', label: t('welcome-page.additional-topics-divination') },
      ],
    },
  ]

  const toggleOption = (questionKey, optionValue) => {
    const currentSelections = selectedCareerAnswers[questionKey] || []
    const updatedSelections = currentSelections.includes(optionValue)
      ? currentSelections.filter((value) => value !== optionValue)
      : [...currentSelections, optionValue]
    setSelectedCareerAnswers({ ...selectedCareerAnswers, [questionKey]: updatedSelections })
  }

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1)
    } else {
      scrollToWidget()
      setCurrentStep(currentStep + 1)
    }
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const { headline, text, options, key } = questions[currentQuestion]
  const currentSelections = selectedCareerAnswers[key] || []

  return (
    <StyledCombinedCareerPreferencesStep>
      <div className="animated fadeIn">
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>

      <span className="onboarding-headline animated fadeIn">{headline}</span>
      <span className="onboarding-text animated fadeIn">{text}</span>

      <div className="icon-select-wrapper animated fadeIn">
        {options.map(({ value, label, icon }) => (
          <Option
            key={value}
            isSelected={currentSelections.includes(value)}
            onSelect={() => toggleOption(key, value)}
            label={label}
            icon={icon}
          />
        ))}
      </div>

      <BlockButton
        isBottom
        disabled={currentSelections.length === 0} // Validation: Ensure at least one selection
        text={t(
          currentQuestion < questions.length - 1 ? 'welcome-page.next' : 'welcome-page.finish',
        )}
        onClick={handleNext}
      />
    </StyledCombinedCareerPreferencesStep>
  )
}
