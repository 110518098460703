import React from 'react'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_gender.json'
import styled from 'styled-components'
import BlockButton from 'components/buttons/BlockButton'

const StyledPage = styled.div`
  .select-wrapper {
    margin: 1rem 3%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  select {
    width: 94%;
    padding: 0.5rem;
    font-size: 1rem;
    margin: 0 10px;
    border: 1px solid ${({ isError }) => (isError ? 'red' : '#ccc')};
    border-radius: 50px;
    text-align: center;
    background-color: #fff;
    max-width: 160px;
  }
`

export default function FriendsBirthTimeStep({
  currentStep,
  setCurrentStep,
  hours,
  setHours,
  minutes,
  setMinutes,
  setTimeKnown,
}) {
  const { t } = useTranslation('common')

  const handleNext = () => {
    setCurrentStep(currentStep + 1)
  }

  const handleSkip = () => {
    setTimeKnown(false)
    setCurrentStep(currentStep + 1)
  }

  return (
    <StyledPage>
      <div className="animated fadeIn">
        <Lottie options={{ loop: true, autoplay: true, animationData }} height={200} width={200} />
      </div>

      <span className="onboarding-headline animated fadeIn">
        {t('add-friends-page.step-time-headline')}
      </span>
      <span className="onboarding-text animated fadeIn">
        {t('add-friends-page.step-time-text')}
      </span>

      <div className="select-wrapper animated fadeIn">
        <select value={hours} onChange={(e) => setHours(e.target.value)}>
          <option value="">{t('hour')}</option>
          {[...Array(24).keys()].map((h) => (
            <option key={h} value={h.toString().padStart(2, '0')}>
              {h.toString().padStart(2, '0')}
            </option>
          ))}
        </select>

        <select value={minutes} onChange={(e) => setMinutes(e.target.value)}>
          <option value="">{t('minute')}</option>
          {[...Array(60).keys()].map((m) => (
            <option key={m} value={m.toString().padStart(2, '0')}>
              {m.toString().padStart(2, '0')}
            </option>
          ))}
        </select>
      </div>

      <span className="onboarding-text animated fadeIn" style={{ fontSize: '14px' }}>
        <b>{t('hint')}: </b>
        {t('welcome-page.step-time-text-hint')}
      </span>

      <div className="footer-button-area">
        <div className="footer-button-area-inner">
          <BlockButton
            isOutlined
            text={t('i-dont-know')}
            onClick={() => {
              handleSkip()
            }}
          ></BlockButton>

          <BlockButton
            text={t('continue')}
            disabled={!hours || !minutes}
            onClick={() => {
              handleNext()
            }}
          ></BlockButton>
        </div>
      </div>
    </StyledPage>
  )
}
