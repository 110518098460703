import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import CheckmarkCircleIcon from 'components/SVG/CheckmarkCircleIcon'
import BlockButton from 'components/buttons/BlockButton'
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_name.json'

const StyledLifeDreamsStep = styled.div``

const LifeDreamOption = ({ isSelected, onSelect, label, icon }) => (
  <div
    className={`icon-select is-primary-button ${isSelected ? 'is-active' : ''}`}
    onClick={onSelect}
  >
    <div className="icon-select-left">
      <div className="icon-select-icon">{icon}</div>
      <span>{label}</span>
    </div>
    {isSelected && (
      <div className="icon-select-right">
        <CheckmarkCircleIcon />
      </div>
    )}
  </div>
)

export default function LifeDreamsStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  selectedDreams,
  setSelectedDreams,
}) {
  const { t } = useTranslation('common')

  const dreamsOptions = [
    { value: 'impact', icon: '🌟', label: t('welcome-page.step-life-dreams-impact') },
    { value: 'family', icon: '❤️', label: t('welcome-page.step-life-dreams-family') },
    { value: 'happiness', icon: '🌈', label: t('welcome-page.step-life-dreams-happiness') },
    { value: 'knowledge', icon: '📘', label: t('welcome-page.step-life-dreams-knowledge') },
    { value: 'other', icon: '❓', label: t('welcome-page.step-life-dreams-other') },
  ]

  const toggleDream = (dream) => {
    if (selectedDreams.includes(dream)) {
      setSelectedDreams(selectedDreams.filter((d) => d !== dream))
    } else {
      setSelectedDreams([...selectedDreams, dream])
    }
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <StyledLifeDreamsStep>
      <div className="animated fadeIn">
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>

      <span className="onboarding-headline animated fadeIn">
        {t('welcome-page.step-life-dreams-headline')}
      </span>
      <span className="onboarding-text animated fadeIn">
        {t('welcome-page.step-life-dreams-text')}
      </span>

      <div className="icon-select-wrapper animated fadeIn">
        {dreamsOptions.map(({ value, label, icon }) => (
          <LifeDreamOption
            key={value}
            isSelected={selectedDreams.includes(value)}
            onSelect={() => toggleDream(value)}
            label={label}
            icon={icon}
          />
        ))}
      </div>

      <BlockButton
        isBottom
        disabled={selectedDreams.length === 0}
        text={t('continue')}
        onClick={() => {
          scrollToWidget()
          setCurrentStep(currentStep + 1)
        }}
      />
    </StyledLifeDreamsStep>
  )
}
