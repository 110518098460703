import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next' // Import useTranslation hook
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_benefits.json'
import BlockButton from 'components/buttons/BlockButton'
import ReviewCardSimple from 'components/ReviewCardSimple'
import { API_URL, BASE_URL } from 'constants/URLS'
import styled from 'styled-components'
import { getZodiacSign } from 'helper/getZodiac'

const StyledAstrologyBlueprintStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .zodiac-preview {
    width: 94%;
    max-width: 343px;
    margin: 80px 3% 0;
    overflow: hidden;
    border-radius: 50%;

    img {
      object-fit: cover;
      width: 100%;
      height: 250px;
    }
  }
`

export default function YourZodiacStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  day,
  month,
  gender,
}) {
  const { t } = useTranslation('common')

  const [zodiac, setZodiac] = useState('')

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  })

  useEffect(() => {
    const zodiacSign = getZodiacSign(day, month)
    setZodiac(zodiacSign)
  }, [])

  return (
    <StyledAstrologyBlueprintStep>
      <div className="zodiac-preview">
        <img
          src={`${API_URL}/api/images/watercolor/zodiac-${zodiac
            .toLowerCase()
            .replace(/ /g, '_')}.jpg`}
          alt=""
        />
      </div>

      <span className="onboarding-headline animated fadeIn">{t(zodiac)}</span>

      <span className="onboarding-text animated fadeIn">{t(`welcome-page.${zodiac}-text`)}</span>

      <BlockButton
        isBottom
        text={t('continue')}
        onClick={() => {
          scrollToWidget()
          setCurrentStep(currentStep + 1)
        }}
      ></BlockButton>
    </StyledAstrologyBlueprintStep>
  )
}
