import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_name.json'
import BlockButton from 'components/buttons/BlockButton'
import CheckmarkCircleIcon from 'components/SVG/CheckmarkCircleIcon'

const BirthChartOption = ({ isSelected, onSelect, label, icon }) => (
  <div
    className={`icon-select is-primary-button ${isSelected ? 'is-active' : ''}`}
    onClick={onSelect}
  >
    <div className="icon-select-left">
      <div className="icon-select-icon">{icon}</div>
      <span>{label}</span>
    </div>
    <div className="icon-select-right">
      <div className="icon-select-checkmark">
        <CheckmarkCircleIcon />
      </div>
    </div>
  </div>
)

export default function BirthChartAnalysisStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  selectedAnalysisAnswers,
  setSelectedAnalysisAnswers,
}) {
  const { t } = useTranslation('common')

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const options = [
    {
      value: 'personality-traits',
      icon: '🧠',
      label: t('welcome-page.birth-chart-analysis-personality-traits'),
    },
    {
      value: 'compatibility-guide',
      icon: '❤️',
      label: t('welcome-page.birth-chart-analysis-compatibility-guide'),
    },
    { value: 'life-path', icon: '🌍', label: t('welcome-page.birth-chart-analysis-life-path') },
    {
      value: 'detailed-horoscopes',
      icon: '📅',
      label: t('welcome-page.birth-chart-analysis-detailed-horoscopes'),
    },
    {
      value: 'upcoming-challenges',
      icon: '⚠️',
      label: t('welcome-page.birth-chart-analysis-upcoming-challenges'),
    },
    { value: 'other', icon: '❓', label: t('welcome-page.birth-chart-analysis-other') },
  ]

  const toggleOption = (value) => {
    const updatedAnswers = selectedAnalysisAnswers.includes(value)
      ? selectedAnalysisAnswers.filter((v) => v !== value)
      : [...selectedAnalysisAnswers, value]
    setSelectedAnalysisAnswers(updatedAnswers)
  }

  return (
    <div>
      <div className="animated fadeIn">
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>
      <span className="onboarding-headline animated fadeIn">
        {t('welcome-page.birth-chart-analysis-headline')}
      </span>

      <div className="icon-select-wrapper animated fadeIn">
        {options.map(({ value, label, icon }) => (
          <BirthChartOption
            key={value}
            isSelected={selectedAnalysisAnswers.includes(value)} // `.includes` works because default is []
            onSelect={() => toggleOption(value)}
            icon={icon}
            label={label}
          />
        ))}
      </div>

      <BlockButton
        isBottom
        disabled={selectedAnalysisAnswers.length === 0} // Require at least one selection
        text={t('continue')}
        onClick={() => {
          scrollToWidget()
          setCurrentStep(currentStep + 1)
        }}
      />
    </div>
  )
}
