import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import CheckmarkCircleIcon from 'components/SVG/CheckmarkCircleIcon'
import BlockButton from 'components/buttons/BlockButton'
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_name.json'

const StyledMotivationStep = styled.div``

const MotivationOption = ({ isSelected, onSelect, label, icon }) => (
  <div
    className={`icon-select is-primary-button ${isSelected ? 'is-active' : ''}`}
    onClick={onSelect}
  >
    <div className="icon-select-left">
      <div className="icon-select-icon">{icon}</div>
      <span>{label}</span>
    </div>
    {isSelected && (
      <div className="icon-select-right">
        <CheckmarkCircleIcon />
      </div>
    )}
  </div>
)

export default function MotivationStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  selectedMotivations,
  setSelectedMotivations,
}) {
  const { t } = useTranslation('common')

  const motivationOptions = [
    { value: 'goals', icon: '🏆', label: t('welcome-page.step-motivation-goals') },
    { value: 'helping', icon: '🤝', label: t('welcome-page.step-motivation-helping') },
    { value: 'learning', icon: '📘', label: t('welcome-page.step-motivation-learning') },
    { value: 'creating', icon: '🎨', label: t('welcome-page.step-motivation-creating') },
    { value: 'other', icon: '❓', label: t('welcome-page.step-motivation-other') },
  ]

  const toggleMotivation = (motivation) => {
    if (selectedMotivations.includes(motivation)) {
      setSelectedMotivations(selectedMotivations.filter((m) => m !== motivation))
    } else {
      setSelectedMotivations([...selectedMotivations, motivation])
    }
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <StyledMotivationStep>
      <div className="animated fadeIn">
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>

      <span className="onboarding-headline animated fadeIn">
        {t('welcome-page.step-motivation-headline')}
      </span>
      <span className="onboarding-text animated fadeIn">
        {t('welcome-page.step-motivation-text')}
      </span>

      <div className="icon-select-wrapper animated fadeIn">
        {motivationOptions.map(({ value, label, icon }) => (
          <MotivationOption
            key={value}
            isSelected={selectedMotivations.includes(value)}
            onSelect={() => toggleMotivation(value)}
            label={label}
            icon={icon}
          />
        ))}
      </div>

      <BlockButton
        isBottom
        disabled={selectedMotivations.length === 0}
        text={t('welcome-page.continue')}
        onClick={() => {
          scrollToWidget()
          setCurrentStep(currentStep + 1)
        }}
      />
    </StyledMotivationStep>
  )
}
