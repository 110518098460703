import styled from 'styled-components'

export const StyledOnboarding = styled.div`
  margin: 0 auto;
  min-height: 90vh;
  padding: 0;
  width: 100%;

  .container-fullwidth {
    width: 100%;
  }

  .onboarding-review-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .story-text {
    color: rgb(46, 59, 75);
    font-family: now, sans-serif;
    font-size: 16px;
    text-align: center;
    line-height: 1.4;
    margin: 20px 10% 10px;
    width: 90%;
    max-width: 740px;
    z-index: 99;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: 375px) {
      font-size: 14px;
    }

    .headline {
      font-family: 'clever-german', serif;
      font-size: 18px;
      line-height: 1.2;
      font-weight: 700 !important;
      color: #111;
      color: #c19c7b;
      margin: 0 0 10px;
    }

    b {
      color: #c19c7b;
      //font-family: 'breathing', sans-serif;
      font-size: 30px;
      letter-spacing: -1px;
      margin-bottom: 20px;
      display: block;

      @media only screen and (max-width: 375px) {
        font-size: 24px;
      }
    }

    h6 {
      font-size: 12px;
    }

    .tell-me-more {
      margin-top: 20px;
      background-color: #fff;
      border-radius: 10px;
      font-size: 14px;
      display: inline-block;
      padding: 10px 20px;
      color: #7f8aa5;
      border-left: 1px solid #eee;
    }
  }
`
