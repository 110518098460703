import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_gender.json'
import styled from 'styled-components'
import * as dayjs from 'dayjs'
import BlockButton from 'components/buttons/BlockButton'

const StyledPage = styled.div`
  .select-wrapper {
    margin: 1rem 0;
  }

  select {
    width: 94%;
    padding: 0.5rem;
    font-size: 1rem;
    margin: 0 3% 20px;
    border: 1px solid ${({ isError }) => (isError ? 'red' : '#ccc')};
    border-radius: 50px;
    text-align: center;
    background-color: #fff;
  }

  .error {
    color: red;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    text-align: center;
  }
`

export default function BirthdayDateStep({
  currentStep,
  setCurrentStep,
  day,
  setDay,
  month,
  setMonth,
  year,
  setYear,
}) {
  const { t } = useTranslation('common')
  const [error, setError] = useState(false)

  const handleNext = () => {
    setError(false)

    const dayNum = parseInt(day, 10)
    const monthNum = parseInt(month, 10)
    const yearNum = parseInt(year, 10)
    const dateString = `${yearNum}-${monthNum}-${dayNum}`
    const date = dayjs(dateString)

    if (
      !date.isValid() ||
      date.date() !== dayNum ||
      date.month() + 1 !== monthNum ||
      date.year() !== yearNum
    ) {
      setError(true)
      return
    }

    setCurrentStep(currentStep + 1)
  }

  return (
    <StyledPage isError={error}>
      <div className="animated fadeIn">
        <Lottie options={{ loop: true, autoplay: true, animationData }} height={200} width={200} />
      </div>

      <span className="onboarding-headline animated fadeIn">{t('welcome-page.step-1-h1')}</span>
      <span className="onboarding-text animated fadeIn" style={{ marginBottom: '20px' }}>
        {t('welcome-page.step-1-span')}
      </span>

      <div className="select-wrapper animated fadeIn">
        <select value={day} onChange={(e) => setDay(e.target.value)}>
          <option value="" disabled>
            {t('DD')}
          </option>
          {[...Array(31).keys()].map((d) => (
            <option key={d + 1} value={d + 1}>
              {d + 1}
            </option>
          ))}
        </select>

        <select value={month} onChange={(e) => setMonth(e.target.value)}>
          <option value="0" disabled>
            {t('MM')}
          </option>
          <option value="1">{t('january')}</option>
          <option value="2">{t('february')}</option>
          <option value="3">{t('march')}</option>
          <option value="4">{t('april')}</option>
          <option value="5">{t('may')}</option>
          <option value="6">{t('june')}</option>
          <option value="7">{t('july')}</option>
          <option value="8">{t('august')}</option>
          <option value="9">{t('september')}</option>
          <option value="10">{t('october')}</option>
          <option value="11">{t('november')}</option>
          <option value="12">{t('december')}</option>
        </select>

        <select value={year} onChange={(e) => setYear(e.target.value)}>
          <option value="" disabled>
            {t('YYYY')}{' '}
          </option>
          {[...Array(120).keys()]
            .map((y) => new Date().getFullYear() - y)
            .map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
        </select>
      </div>

      {error && <div className="error">{t('error.invalid-date')}</div>}

      <BlockButton
        isBottom
        disabled={!day || !month || !year}
        text={t('continue')}
        onClick={() => {
          handleNext()
        }}
      />
    </StyledPage>
  )
}
