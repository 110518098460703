import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import CheckmarkCircleIcon from 'components/SVG/CheckmarkCircleIcon'
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_name.json'

const StyledCombinedAgreementStep = styled.div``

const AgreementOption = ({ isSelected, onSelect, label, icon }) => (
  <div
    className={`icon-select is-primary-button ${isSelected ? 'is-active' : ''}`}
    onClick={onSelect}
  >
    <div className="icon-select-left">
      <div className="icon-select-icon">{icon}</div>
      <span>{label}</span>
    </div>
    {isSelected && (
      <div className="icon-select-right">
        <CheckmarkCircleIcon />
      </div>
    )}
  </div>
)

export default function CombinedAgreementStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  answers,
  setAnswers,
}) {
  const { t } = useTranslation('common')
  const [currentQuestion, setCurrentQuestion] = useState(0)

  const questions = [
    {
      key: 'worry-right-decision',
      headline: t('welcome-page.agreement-worry-headline'),
      text: t('welcome-page.agreement-worry-text'),
      options: [
        { value: 'strongly-agree', icon: '👍', label: t('welcome-page.agreement-strongly-agree') },
        { value: 'somewhat-agree', icon: '🤔', label: t('welcome-page.agreement-somewhat-agree') },
        { value: 'disagree', icon: '👎', label: t('welcome-page.agreement-disagree') },
      ],
    },
    {
      key: 'need-others-like',
      headline: t('welcome-page.agreement-need-like-headline'),
      text: t('welcome-page.agreement-need-like-text'),
      options: [
        { value: 'always', icon: '😊', label: t('welcome-page.agreement-always') },
        { value: 'sometimes', icon: '🤔', label: t('welcome-page.agreement-sometimes') },
        { value: 'rarely', icon: '🙃', label: t('welcome-page.agreement-rarely') },
        { value: 'not-at-all', icon: '😐', label: t('welcome-page.agreement-not-at-all') },
      ],
    },
    {
      key: 'insecure-people',
      headline: t('welcome-page.agreement-insecure-headline'),
      text: t('welcome-page.agreement-insecure-text'),
      options: [
        { value: 'strongly-agree', icon: '👍', label: t('welcome-page.agreement-strongly-agree') },
        { value: 'somewhat-agree', icon: '🤔', label: t('welcome-page.agreement-somewhat-agree') },
        { value: 'disagree', icon: '👎', label: t('welcome-page.agreement-disagree') },
      ],
    },
    {
      key: 'pessimist-optimist',
      headline: t('welcome-page.agreement-optimist-headline'),
      text: t('welcome-page.agreement-optimist-text'),
      options: [
        { value: 'pessimist', icon: '☁️', label: t('welcome-page.agreement-pessimist') },
        { value: 'optimist', icon: '☀️', label: t('welcome-page.agreement-optimist') },
        { value: 'realist', icon: '⚖️', label: t('welcome-page.agreement-realist') },
      ],
    },
  ]

  const handleSelect = (value) => {
    setAnswers({ ...answers, [questions[currentQuestion].key]: value })
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1)
    } else {
      scrollToWidget()
      setCurrentStep(currentStep + 1)
    }
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const { headline, text, options } = questions[currentQuestion]

  return (
    <StyledCombinedAgreementStep>
      <div className="animated fadeIn">
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>

      <span className="onboarding-headline animated fadeIn">{headline}</span>
      <span className="onboarding-text animated fadeIn">{text}</span>

      <div className="icon-select-wrapper animated fadeIn">
        {options.map(({ value, label, icon }) => (
          <AgreementOption
            key={value}
            isSelected={answers[questions[currentQuestion].key] === value}
            onSelect={() => handleSelect(value)}
            label={label}
            icon={icon}
          />
        ))}
      </div>
    </StyledCombinedAgreementStep>
  )
}
