import React, { useState } from 'react'
import { useTranslation } from 'react-i18next' // Import useTranslation hook
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_benefits.json'
import BlockButton from 'components/buttons/BlockButton'

export default function AstrologyFamiliarityResultsStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
}) {
  const { t } = useTranslation('common')

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  })

  return (
    <div>
      <div className="animated fadeIn">
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isStopped={defaultOptions.isStopped}
          isPaused={defaultOptions.isPaused}
        />
      </div>

      <span className="onboarding-headline animated fadeIn">
        {t('welcome-page.step-familiarity-result-headline')}
      </span>

      <ul className="onboarding-list">
        <li className="animation animated fadeIn">
          {t('welcome-page.step-familiarity-result-fact-1')}
        </li>
        <li className="animation animated fadeIn">
          {t('welcome-page.step-familiarity-result-fact-2')}
        </li>
        <li className="animation animated fadeIn">
          {t('welcome-page.step-familiarity-result-fact-3')}
        </li>
        {/*<li className="animation animated fadeIn">
          {t('welcome-page.step-familiarity-result-fact-4')}
         </li>*/}
        <li className="animation animated fadeIn">
          {t('welcome-page.step-familiarity-result-fact-5')}
        </li>
        <li className="animation animated fadeIn">
          {t('welcome-page.step-familiarity-result-fact-6')}
        </li>
        <li className="animation animated fadeIn">
          {t('welcome-page.step-familiarity-result-fact-7')}
        </li>
      </ul>

      <BlockButton
        isBottom
        text={t('lets-go')}
        onClick={() => {
          scrollToWidget()
          setCurrentStep(currentStep + 1)
        }}
      ></BlockButton>
    </div>
  )
}
