import React from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import { BASE_URL } from 'constants/URLS'

const StyledContentCardLoading = styled.div`
  position: relative;
  margin: 0;
  display: flex;
  overflow: hidden;
  display: flex;
  align-items: center;
  border: 1px solid whitesmoke;
  border-radius: 0;
  padding-bottom: 20px;
  margin: 20px 3% 20px;
  padding: 0 0 20px 0;
  max-width: 536px;
  cursor: pointer;
  text-align: left;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 0 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5px 0.5px 0px;

  @media (min-width: 720px) {
    margin: 20px auto 20px;
  }

  .content-card-image {
    width: 60px;
    min-width: 60px;
    min-height: 60px;
    height: 60px;
    //flex: 1;
    //align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    font-size: 24px;
    position: relative;
    margin-right: 0;
    font-family: 'clever-german', serif;
    background-color: #fff;
    border: 1px solid whitesmoke;
    border-radius: 10px;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      /*background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 80%,
        rgba(255, 255, 255, 1) 100%
      );*/
    }
  }

  @keyframes react-loading-skeleton {
    100% {
      transform: translateX(100%);
    }
  }

  .react-loading-skeleton {
    --base-color: #ebebeb;
    --highlight-color: #f5f5f5;
    --animation-duration: 1.5s;
    --animation-direction: normal;
    --pseudo-element-display: block; /* Enable animation */

    background-color: var(--base-color);

    width: 100%;
    border-radius: 0.25rem;
    display: inline-flex;
    line-height: 1;

    position: relative;
    user-select: none;
    overflow: hidden;
  }

  .react-loading-skeleton::after {
    content: ' ';
    display: var(--pseudo-element-display);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: var(
      --custom-highlight-background,
      linear-gradient(
        90deg,
        var(--base-color) 0%,
        var(--highlight-color) 50%,
        var(--base-color) 100%
      )
    );
    transform: translateX(-100%);

    animation-name: react-loading-skeleton;
    animation-direction: var(--animation-direction);
    animation-duration: var(--animation-duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  @media (prefers-reduced-motion) {
    .react-loading-skeleton {
      --pseudo-element-display: none; /* Disable animation */
    }
  }

  .content-card-text {
    margin: 0 20px;
    overflow: hidden;
    width: 100%;
  }
`

export default function ContentCardLoading() {
  return (
    <StyledContentCardLoading>
      <div
        className="content-card-image"
        style={{
          backgroundImage: `url(${BASE_URL}/api/images/placeholder.jpg)`,
        }}
      ></div>
      <div className="content-card-text">
        <div className="content-card-title">
          <Skeleton width={100} count={1} />
          <Skeleton count={1} />
        </div>
      </div>
    </StyledContentCardLoading>
  )
}
