import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_name.json'
import BlockButton from 'components/buttons/BlockButton'

const StyledNameStep = styled.div`
  .input-wrapper {
    margin: 20px auto;
    text-align: center;

    input {
      width: 80%;
      padding: 10px;
      font-size: 16px;
      border-radius: 50px;
      text-align: center;
      background-color: #fff;
      border: 1px solid #ddd;
      outline: none;
    }

    input:focus {
      border-color: #c19c7b; /* Adjust to your theme color */
    }
  }
`

export default function NameStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  firstName,
  setFirstName,
  lastName,
  setLastName,
}) {
  const { t } = useTranslation('common')
  const [isFirstNameStep, setIsFirstNameStep] = useState(true) // Toggle between first name and last name step
  const [inputValue, setInputValue] = useState(isFirstNameStep ? firstName : lastName)

  const handleNext = () => {
    if (isFirstNameStep) {
      setFirstName(inputValue) // Save the first name
      scrollToWidget()
      setIsFirstNameStep(false) // Move to the last name step
      setInputValue(lastName) // Reset input for last name
    } else {
      setLastName(inputValue) // Save the last name
      scrollToWidget()
      setCurrentStep(currentStep + 1) // Move to the next step
    }
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <StyledNameStep>
      <div className="animated fadeIn">
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>

      <span className="onboarding-headline animated fadeIn">
        {isFirstNameStep
          ? t('welcome-page.step-first-name-headline')
          : t('welcome-page.step-last-name-headline')}
      </span>

      <span className="onboarding-text animated fadeIn">
        {isFirstNameStep
          ? t('welcome-page.step-first-name-text')
          : t('welcome-page.step-last-name-text')}
      </span>

      <div className="input-wrapper animated fadeIn">
        <input
          type="text"
          placeholder={
            isFirstNameStep
              ? t('welcome-page.step-first-name-placeholder')
              : t('welcome-page.step-last-name-placeholder')
          }
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </div>

      <BlockButton
        isBottom
        disabled={inputValue.trim() === ''} // Disable if input is empty
        text={t('welcome-page.continue')}
        onClick={handleNext}
      >
        {t('welcome-page.continue')}
      </BlockButton>
    </StyledNameStep>
  )
}
