import React, { useState, useEffect, useRef } from 'react'
import { useAPI } from 'context/APIcontext'
import { sendTrackingPixel } from 'helper/tracking'
import { useTranslation } from 'react-i18next'
import WelcomeStep from '../../components/OnboardingSteps/WelcomeStep'
import { StyledOnboarding } from './StyledOnboarding'
import BornStep from '../../components/OnboardingSteps/BornStep'
import BirthDataStep from '../../components/OnboardingSteps/BirthData'
import GenderStep from '../../components/OnboardingSteps/GenderStep'
import ArrowBack from 'components/SVG/ArrowBack'
import FinishOnboardingStep from '../../components/OnboardingSteps/FinishOnboarding'
import LandingPage from 'pages/LandingPage/LandingPage'
import LoginStep from '../../components/OnboardingSteps/LoginStep'
import * as dayjs from 'dayjs'
import { BASE_URL } from 'constants/URLS'
import AstrologyFamiliarityStep from '../../components/OnboardingSteps/AstrologyFamiliarityStep'
import AstrologyFamiliarityResultsStep from '../../components/OnboardingSteps/AstrologyFamiliarityResultsStep'
import GoalsStep from '../../components/OnboardingSteps/GoalsStep'
import RelationshipStatusStep from '../../components/OnboardingSteps/RelationshipStatusStep'
import RelationshipGoalsStep from '../../components/OnboardingSteps/RelationshipGoalsStep'
import GeneratingFirstStep from '../../components/OnboardingSteps/GeneratingFirstStep'
import AstrologyBlueprintStep from '../../components/OnboardingSteps/AstrologyBlueprint'
import BirthdayDateStep from '../../components/OnboardingSteps/BirthdayDateStep'
import BirthTimeStep from '../../components/OnboardingSteps/BirthTimeStep'
import YourZodiacStep from '../../components/OnboardingSteps/YourZodiac'
import GeneratingSecondStep from '../../components/OnboardingSteps/GeneratingSecondStep'
import OnlyPercentStep from '../../components/OnboardingSteps/OnlyPercentStep'
import LifeDreamsStep from '../../components/OnboardingSteps/LifeDreamOption'
import MotivationStep from '../../components/OnboardingSteps/MotivationStep'
import CombinedAgreementStep from '../../components/OnboardingSteps/CombinedAgreementStep'
import AgreementResults from '../../components/OnboardingSteps/AgreementResult'
import CombinedRelationshipStep from '../../components/OnboardingSteps/CombinedRelationshipStep'
import CombinedRelationshipResult from '../../components/OnboardingSteps/CombinedRelationshipResult'
import CombinedCareerPreferencesStep from '../../components/OnboardingSteps/CombinedCareerPreferencesStep'
import NameStep from '../../components/OnboardingSteps/NameStep'
import CoachingAreasStep from '../../components/OnboardingSteps/CoachingAreasStep'
import { getZodiacSign } from 'helper/getZodiac'
import AdditionalTopicsStep from '../../components/OnboardingSteps/AdditionalTopics'
import BirthChartAnalysisStep from '../../components/OnboardingSteps/BirthChartAnalysis'

export default function OnboardingPage({ onClose }) {
  const { t } = useTranslation('common')
  const myRef = useRef(null)

  const { userData, setUserData, userLoggedIn, setUserLoggedIn, setUserID, setUserDisplayName } =
    useAPI()

  const [currentStep, setCurrentStep] = useState(0)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [gender, setGender] = useState('')
  const [location, setLocation] = useState()
  const [day, setDay] = useState('')
  const [month, setMonth] = useState('0')
  const [year, setYear] = useState('')
  const [timeknown, setTimeKnown] = useState(false)
  const [hours, setHours] = useState()
  const [minutes, setMinutes] = useState()
  const [familiarity, setFamiliarity] = useState('')

  const [relationshipStatus, setRelationshipStatus] = useState('')
  const [selectedRelationshipGoals, setselectedRelationshipGoals] = useState([])
  const [selectedGoals, setSelectedGoals] = useState([])
  const [relationshipAnswers, setRelationshipAnswers] = useState({})
  const [selectedCareerAnswers, setSelectedCareerAnswers] = useState({})

  const [housesData, setHousesData] = useState([])
  const [planetsData, setPlanetsData] = useState([])

  const [selectedDreams, setSelectedDreams] = useState([])
  const [selectedMotivations, setSelectedMotivations] = useState([])
  const [answers, setAnswers] = useState({})

  const [selectedAnalysisAnswers, setSelectedAnalysisAnswers] = useState([])

  const [selectedAdditionalTopics, setSelectedAdditionalTopics] = useState([])
  const [selectedCoachingAreas, setSelectedCoachingAreas] = useState([])
  const [loginState, setLoginState] = useState('register')
  const [skippedToLogin, setSkippedToLogin] = useState(false)

  const [stepPercentage, setStepPercentage] = useState(0)

  useEffect(() => {
    setStepPercentage(Math.round((currentStep * 100) / steps.length))
  }, [currentStep])

  useEffect(() => {
    const pixelEvent =
      window.location.pathname === '/'
        ? 'view-onboarding-page-home'
        : 'view-onboarding-page-article'
    sendTrackingPixel(pixelEvent)
  }, [])

  function scrollToWidget() {
    myRef.current.scrollIntoView()
  }

  function goToLoginStep() {
    setSkippedToLogin(true)
    setLoginState('login')
    setCurrentStep(17) // Jump to login step
    scrollToWidget()
  }

  async function handleLoginUser(data) {
    const userID = data[0]
    const display_name = data[1]
    setUserID(userID)
    setUserDisplayName(display_name)
  }

  async function handleSetUserData(data) {
    const userID = data[0]
    const display_name = data[1]
    const zodiacSign = getZodiacSign(Number(day), Number(month))

    let newUserData = { ...userData }
    newUserData.userProfile = {
      firstName: firstName,
      lastName: lastName,
      isMe: true,
      relationship: '',
      focusTopics: [],
      lastLogin: dayjs().format(),
      birthYear: year,
      birthMonth: month,
      birthDay: day,
      birthHour: hours,
      birthMinute: minutes,
      location: location,
      knowExactTime: timeknown,
      zodiacSign: zodiacSign,
      familiarity: familiarity,
      selectedCoachingAreas: selectedCoachingAreas,
      selectedGoals: selectedGoals,
      relationshipStatus: relationshipStatus,
      selectedRelationshipGoals: selectedRelationshipGoals,
      selectedDreams: selectedDreams,
      selectedMotivations: selectedMotivations,
      answers: answers,
      relationshipAnswers: relationshipAnswers,
      selectedCareerAnswers: selectedCareerAnswers,
      selectedAdditionalTopics: selectedAdditionalTopics,
    }

    localStorage.setItem('newUserProfile', JSON.stringify(newUserData))

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newUserData),
    }

    await fetch(
      `${BASE_URL}/api-user/?user_id=${userID}&display_name=${display_name}`,
      requestOptions,
    )
      .then((response) => response.json())
      .then((data) => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
          console.log('data saved: ', data)
        }
      })

    setCurrentStep((prevStep) => prevStep + 1)
    setUserLoggedIn(true)
    setUserData(newUserData)
  }

  const steps = [
    <WelcomeStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      location={location}
      setLocation={setLocation}
      state={loginState}
      handleGoToLogin={goToLoginStep}
    />,

    <GenderStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      gender={gender}
      setGender={setGender}
    />,

    <AstrologyFamiliarityStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      familiarity={familiarity}
      setFamiliarity={setFamiliarity}
    />,
    <AstrologyFamiliarityResultsStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
    />,

    /*
    <RelationshipStatusStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      relationshipStatus={relationshipStatus}
      setRelationshipStatus={setRelationshipStatus}
    />,
    <RelationshipGoalsStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      selectedRelationshipGoals={selectedRelationshipGoals}
      setselectedRelationshipGoals={setselectedRelationshipGoals}
      relationshipStatus={relationshipStatus}
    />,
    <GeneratingFirstStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
    />,*/

    <BornStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      location={location}
      setLocation={setLocation}
    />,
    <BirthdayDateStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      day={day}
      setDay={setDay}
      month={month}
      setMonth={setMonth}
      year={year}
      setYear={setYear}
    />,
    <BirthTimeStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      hours={hours}
      setHours={setHours}
      minutes={minutes}
      setMinutes={setMinutes}
      timeknown={timeknown}
      setTimeKnown={setTimeKnown}
    />,
    <GeneratingSecondStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      day={day}
      month={month}
      year={year}
      hours={hours}
      minutes={minutes}
      location={location}
      gender={gender}
      setHousesData={setHousesData}
      setPlanetsData={setPlanetsData}
      houseData={housesData}
      planetData={planetsData}
    ></GeneratingSecondStep>,
    <OnlyPercentStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      day={day}
      month={month}
      year={year}
      hours={hours}
      minutes={minutes}
      gender={gender}
      location={location}
      houseData={housesData}
      planetData={planetsData}
    />,
    /*<AstrologyBlueprintStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
    />*/ <CoachingAreasStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      selectedCoachingAreas={selectedCoachingAreas}
      setSelectedCoachingAreas={setSelectedCoachingAreas}
    />,
    <MotivationStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      selectedMotivations={selectedMotivations}
      setSelectedMotivations={setSelectedMotivations}
    />,
    <LifeDreamsStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      selectedDreams={selectedDreams}
      setSelectedDreams={setSelectedDreams}
    ></LifeDreamsStep>,

    <CombinedAgreementStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      answers={answers}
      setAnswers={setAnswers}
    ></CombinedAgreementStep>,

    <AgreementResults
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
    ></AgreementResults>,
    <BirthChartAnalysisStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      selectedAnalysisAnswers={selectedAnalysisAnswers}
      setSelectedAnalysisAnswers={setSelectedAnalysisAnswers}
    />,
    <AdditionalTopicsStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      selectedAdditionalTopics={selectedAdditionalTopics}
      setSelectedAdditionalTopics={setSelectedAdditionalTopics}
    />,

    <NameStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
    />,
    <LoginStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      location={location}
      setLocation={setLocation}
      loginState={loginState}
      onLogin={(data) => handleLoginUser(data)}
      onRegister={(data) => handleSetUserData(data)}
    />,
    <FinishOnboardingStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      handleFinishOnboarding={onClose}
    />,
  ]

  return (
    <>
      {userLoggedIn ? (
        <LandingPage />
      ) : (
        <StyledOnboarding ref={myRef}>
          {currentStep > 0 && (
            <div
              className="go-back"
              onClick={() => {
                if (skippedToLogin) {
                  setCurrentStep(0)
                } else {
                  setCurrentStep((prev) => Math.max(0, prev - 1))
                }
              }}
            >
              <ArrowBack />
            </div>
          )}
          {steps[currentStep]}
        </StyledOnboarding>
      )}
    </>
  )
}
