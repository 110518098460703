import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next' // Import useTranslation hook
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_benefits.json'
import BlockButton from 'components/buttons/BlockButton'
import styled from 'styled-components'
import { getZodiacSign } from 'helper/getZodiac'

const StyledAstrologyBlueprintStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .onboarding-text {
    font-size: 18px;
    margin: 0 10%;

    b {
      white-space: nowrap;
      color: #c19c7b;
    }
  }

  small {
    margin: 40px 10% 0;
    text-align: center;
  }
`

export default function OnlyPercentStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  day,
  month,
  gender,
  houseData,
  planetData,
}) {
  const { t } = useTranslation('common')

  const [zodiac, setZodiac] = useState('')

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  })

  useEffect(() => {
    const zodiacSign = getZodiacSign(day, month)
    setZodiac(zodiacSign)
  }, [])

  return (
    <StyledAstrologyBlueprintStep>
      <div className="animated fadeIn">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: 'slice',
            },
          }}
          height={200}
          width={200}
        />
      </div>

      <span className="onboarding-headline animated fadeIn">
        {t('welcome-page.based-on-your-data')}
      </span>

      {/*<span
        className="onboarding-text animated fadeIn"
        dangerouslySetInnerHTML={{ __html: t(`10_house_onboarding_${houseData[9].zodiac}`) }}
        ></span>*/}

      <span
        className="onboarding-text animated fadeIn"
        dangerouslySetInnerHTML={{ __html: t(`welcome-page.only-percent-have-smililar`) }}
      ></span>

      <small className="animated fadeIn">{t(`welcome-page.lets-dive-into-it`)}</small>

      <BlockButton
        isBottom
        text={t('continue')}
        onClick={() => {
          scrollToWidget()
          setCurrentStep(currentStep + 1)
        }}
      ></BlockButton>
    </StyledAstrologyBlueprintStep>
  )
}
