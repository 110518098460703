import { CHALLENGES_API, FUNFACTS_API } from "constants/URLS";
import { getLanguage } from "./getLanguage";
import { shuffleArray } from "./shuffleArray";
import {
  TASKS_GO_TO_AFFIRMATION,
  TASKS_GO_TO_COACHING,
  TASKS_GO_TO_FUNFACT,
  TASKS_GO_TO_MEDITATION,
  TASKS_GO_TO_TAROT,
  TASK_GO_TO_CHALLENGE,
  TASK_GO_TO_HOROSCOPE,
  TASK_GO_TO_MOONDAY,
} from "constants/TASKS";

/*
Astrologische Wissenshäppchen - Kurze Fakten oder Erklärungen zu astrologischen Konzepten.
Spirituelle Zitate - Inspirierende Zitate von spirituellen Lehrern oder Philosophen.
Meditationsanleitung - Eine kurze Anleitung für eine tägliche Meditation, eventuell mit einem spezifischen Fokus, der astrologisch begründet ist.
Traumtagebuch-Prompt - Anregungen, um Nutzer zu ermutigen, ihre Träume aufzuschreiben und zu reflektieren.
Energiearbeit Übungen - Einfache Techniken zur Reinigung und Stärkung der persönlichen Energie.
Kristall des Tages - Informationen über einen Kristall und seine spirituellen Eigenschaften.
Tarot-Karte des Tages - Ziehung und Interpretation einer Tarot-Karte.
Yoga-Pose des Tages - Eine Yoga-Pose mit Anleitung und deren spiritueller Bedeutung.
Achtsamkeitsübung - Eine kurze Übung zur Förderung der Achtsamkeit.
Spirituelles Buch des Monats - Empfehlungen für spirituelle Lektüre.
Astrologische Aspekte des Tages - Erläuterung besonderer astrologischer Konstellationen des Tages.
Numerologie des Tages - Einblick in die numerologische Bedeutung des Tages.
Farbe des Tages - Bedeutung und Einfluss einer spezifischen Farbe auf den Tag.
Räucherstäbchen des Tages - Empfehlung für ein Räucherstäbchen und dessen spirituelle Wirkung.
Engelbotschaft des Tages - Eine Botschaft oder Anleitung von den Engeln.
Spirituelle Herausforderung der Woche - Eine wöchentliche Aufgabe, die spirituelles Wachstum fördert.
Klangmeditation - Eine kurze geführte Klangmeditation mit Schalen oder Gongs.
Chakren-Check - Tipps zur Harmonisierung und Balancierung der Chakren.
Pflanze des Tages - Informationen über eine Pflanze und ihre spirituellen Eigenschaften.
Mondritual-Anleitung - Anleitungen für Rituale, die mit dem aktuellen Mondstand in Verbindung stehen.
Spirituelle Reiseziele - Vorstellung von Orten mit spiritueller Bedeutung.
Göttin des Tages - Informationen über eine Göttin und wie man ihre Energie nutzen kann.
Selbstreflexionsfragen - Fragen, die zur Selbstreflexion und persönlichem Wachstum anregen.
Astrologisches Profil berühmter Persönlichkeiten - Einblick in das astrologische Profil einer bekannten Persönlichkeit.
Feng Shui Tipp des Tages - Tipps zur Verbesserung der Energie in Wohn- oder Arbeitsräumen.
Heilige Geometrie des Tages - Einblick in die Bedeutung und Anwendung heiliger geometrischer Formen.
Spirituelle Filmempfehlung - Filme, die spirituelle Themen behandeln oder zum Nachdenken anregen.
Tägliche Entspannungstechniken - Anleitungen für Techniken zur Reduzierung von Stress und Angst.
Visualisierungsübung - Eine geführte Visualisierung für positive Energie und Manifestation.
Spirituelles Experiment der Woche - Eine wöchentliche Aufgabe, die zum Ausprobieren neuer spiritueller Praktiken anregt.
Geschichte des Tages - Eine kurze Geschichte oder Anekdote mit einer moralischen oder spirituellen Lektion.
Körperliche Übung mit spirituellem Fokus - Übungen, die sowohl den Körper stärken als auch einen spirituellen Aspekt haben.
Kreative Ausdrucksform des Tages - Anregungen für kreativen Ausdruck wie Malen, Schreiben oder Tanzen.
Tägliche Dankbarkeitsübung - Anleitung, um täglich Dankbarkeit zu üben und zu dokumentieren.
Persönliche Grenzen setzen - Tipps und Übungen zum Setzen und Bewahren persönlicher Grenzen.
Spirituelle Podcast-Empfehlung - Vorstellung von Podcasts zu spirituellen Themen.
Astrologische Planungstipps - Ratschläge, wie man seine Woche oder seinen Tag gemäß astrologischer Einflüsse plant.
Energetische Reinigungsrituale - Anleitungen für Rituale zur energetischen Reinigung von sich selbst oder seinem Umfeld.
Tiergeistführer des Tages - Informationen über Tiergeistführer und ihre Bedeutungen.
Tägliche Intention setzen - Anleitung, wie man eine kraftvolle Intention für den Tag setzt.
*/
function getChallengesList() {
  const challengesURL = `${CHALLENGES_API}?language=${getLanguage()}`;

  return fetch(challengesURL).then((response) => response.json());
}

function getFunFactList() {
  const funFactsURL = `${FUNFACTS_API}?language=${getLanguage()}`;

  return fetch(funFactsURL).then((response) => response.json());
}

export async function generateTaskList(
  newUserData,
  coachingSessions,
  moonData,
  t,
) {
  const challengesList = await getChallengesList();
  //const funfactsList = await getFunFactList()
  //const fullTipList = await this.appData.getTipList();
  const randomNumber = Math.floor(Math.random() * Math.floor(4));

  //console.log('funfactsList', funfactsList)

  console.log(
    "newUserData",
    newUserData.userProfile.selectedCoachingAreas,
    coachingSessions.sessions_v3,
  );

  /*
  const filterCoachingByUserPreference = coachingSessions.sessions_v3.filter((coaching) => {
    return coaching.categories.some((category) =>
      newUserData.userProfile.selectedCoachingAreas.includes(category),
    )
  })

  console.log(filterCoachingByUserPreference)*/

  let todaysTasks = [];

  todaysTasks.push({
    isPremium: false,
    acountNeeded: false,
    title: `${t("moon-in")} ${t(moonData.zodiac[0])}`,
    intro: `${t("task.moon-today")}`,
    content: ``,
    action: TASK_GO_TO_MOONDAY,
    topic: `zodiac-${moonData.zodiac[0]}`,
    icon: "",
  });

  todaysTasks.push({
    isPremium: false,
    acountNeeded: true,
    title: `${t("tasks.todays-horoscope")}`,
    intro: `${t("tasks.todays-horoscope-text")}`,
    content: ``,
    action: TASK_GO_TO_HOROSCOPE,
    topic: "horoscope",
    icon: "",
  });

  let challenge = shuffleArray(challengesList)[0];
  todaysTasks.push({
    isPremium: false,
    acountNeeded: true,
    title: `${t("tasks.todays-task")}`,
    intro: challenge.title,
    content: challenge,
    action: TASK_GO_TO_CHALLENGE,
    topic: "mountain",
    icon: "",
  });

  todaysTasks.push({
    isPremium: false,
    acountNeeded: false,
    title: `${t("tasks.affirmation")}`,
    intro: `${t("tasks.affirmation-text")}`,
    content: ``,
    action: TASKS_GO_TO_AFFIRMATION,
    topic: "affirmation",
    icon: "",
  });

  todaysTasks.push({
    isPremium: false,
    acountNeeded: false,
    title: `${t("tasks.todays-coaching")}`,
    intro: `${t("tasks.todays-coaching-text")}`,
    content: ``,
    action: TASKS_GO_TO_COACHING,
    topic: "coaching",
    icon: "",
  });

  /*todaysTasks.push({
    isPremium: false,
    acountNeeded: false,
    title: `${t('tasks.todays-meditation')}`,
    intro: `${t('tasks.todays-meditation-text')}`,
    content: ``,
    action: TASKS_GO_TO_MEDITATION,
    topic: 'meditation',
    icon: '',
  })

  todaysTasks.push({
    isPremium: false,
    acountNeeded: false,
    title: `${t('tasks.todays-funfact')}`,
    intro: `${t('tasks.todays-funfact-text')}`,
    content: ``,
    action: TASKS_GO_TO_FUNFACT,
    topic: 'meditation',
    icon: '',
  })*/

  todaysTasks.push({
    isPremium: false,
    acountNeeded: false,
    title: `${t("tasks.tarot-headline")}`,
    intro: `${t("tasks.tarot-text")}`,
    content: ``,
    action: TASKS_GO_TO_TAROT,
    topic: "tarot",
    icon: "",
  });

  return todaysTasks;
}
