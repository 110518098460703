import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_name.json'
import BlockButton from 'components/buttons/BlockButton'
import CheckmarkCircleIcon from 'components/SVG/CheckmarkCircleIcon'

const AdditionalTopicsOption = ({ isSelected, onSelect, label, icon }) => (
  <div
    className={`icon-select is-primary-button ${isSelected ? 'is-active' : ''}`}
    onClick={onSelect}
  >
    <div className="icon-select-left">
      <div className="icon-select-icon">{icon}</div>
      <span>{label}</span>
    </div>
    <div className="icon-select-right">
      <div className="icon-select-checkmark">
        <CheckmarkCircleIcon />
      </div>
    </div>
  </div>
)

export default function AdditionalTopicsStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  selectedAdditionalTopics = [], // Ensure this is never undefined by providing a default empty array
  setSelectedAdditionalTopics,
}) {
  const { t } = useTranslation('common')

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const options = [
    { value: 'tarot', icon: '🔮', label: t('welcome-page.additional-topics-tarot') },
    { value: 'numerology', icon: '🔢', label: t('welcome-page.additional-topics-numerology') },
    {
      value: 'astrological-events',
      icon: '🌌',
      label: t('welcome-page.additional-topics-astrological-events'),
    },
    { value: 'palm-reading', icon: '✋', label: t('welcome-page.additional-topics-palm-reading') },
    { value: 'crystals', icon: '💎', label: t('welcome-page.additional-topics-crystals') },
    { value: 'divination', icon: '📜', label: t('welcome-page.additional-topics-divination') },
  ]

  const toggleOption = (value) => {
    const updatedTopics = selectedAdditionalTopics.includes(value) // Use includes safely here
      ? selectedAdditionalTopics.filter((v) => v !== value)
      : [...selectedAdditionalTopics, value]
    setSelectedAdditionalTopics(updatedTopics)
  }

  return (
    <div>
      <div className="animated fadeIn">
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>
      <span className="onboarding-headline animated fadeIn">
        {t('welcome-page.additional-topics-headline')}
      </span>

      <div className="icon-select-wrapper animated fadeIn">
        {options.map(({ value, label, icon }) => (
          <AdditionalTopicsOption
            key={value}
            isSelected={selectedAdditionalTopics.includes(value)} // Includes now works as state is guaranteed
            onSelect={() => toggleOption(value)}
            icon={icon}
            label={label}
          />
        ))}
      </div>

      <BlockButton
        isBottom
        disabled={selectedAdditionalTopics.length === 0}
        text={t('continue')}
        onClick={() => {
          scrollToWidget()
          setCurrentStep(currentStep + 1)
        }}
      />
    </div>
  )
}
