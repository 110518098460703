import { useTranslation } from 'react-i18next'

export const useCoachingOptions = () => {
  const { t } = useTranslation('common')

  return [
    {
      value: 'Motivation and Inspiration',
      icon: '💡',
      label: t('welcome-page.coaching-motivation'),
    },
    { value: 'Career and Profession', icon: '💼', label: t('welcome-page.coaching-career') },
    {
      value: 'Relationships and Partnership',
      icon: '❤️',
      label: t('welcome-page.coaching-relationships'),
    },
    { value: 'Health and Wellbeing', icon: '🌱', label: t('welcome-page.coaching-health') },
    {
      value: 'Personal Growth and Self-Development',
      icon: '🌟',
      label: t('welcome-page.coaching-growth'),
    },
    {
      value: 'Self-Esteem and Self-Confidence',
      icon: '✨',
      label: t('welcome-page.coaching-self-esteem'),
    },
    {
      value: 'Spirituality and Purpose',
      icon: '🔮',
      label: t('welcome-page.coaching-spirituality'),
    },
    {
      value: 'Time Management and Work-Life Balance',
      icon: '⏳',
      label: t('welcome-page.coaching-time-management'),
    },
    { value: 'Life Vision and Goals', icon: '🎯', label: t('welcome-page.coaching-vision') },
    {
      value: 'Creativity and Self-Expression',
      icon: '🎨',
      label: t('welcome-page.coaching-creativity'),
    },
    {
      value: 'Social Environment and Friendships',
      icon: '👫',
      label: t('welcome-page.coaching-social'),
    },
    {
      value: 'Mental Health and Resilience',
      icon: '🧠',
      label: t('welcome-page.coaching-mental-health'),
    },
    {
      value: 'Coping with Change and Crisis Management',
      icon: '🌪️',
      label: t('welcome-page.coaching-change'),
    },
    {
      value: 'Forgiveness and Letting Go',
      icon: '🌈',
      label: t('welcome-page.coaching-forgiveness'),
    },
    {
      value: 'Stress management and calmness',
      icon: '🧘',
      label: t('welcome-page.coaching-stress-management'),
    },
    {
      value: 'Happiness and positive mindset',
      icon: '😊',
      label: t('welcome-page.coaching-happiness'),
    },
    { value: 'Communication skills', icon: '📞', label: t('welcome-page.coaching-communication') },
  ]
}
