import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { API_URL, ASSETS_URL, BASE_URL } from 'constants/URLS'
import { useTranslation } from 'react-i18next'
import { useAPI } from 'context/APIcontext'
import styled from 'styled-components'
import ContentCard from './ContentCard'
import SectionHeader from './SectionHeader'
import TagCloud from './TagCloud'
import Modal from './Modal'
import StoryPage from 'pages/StoryPage/StoryPage'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import BlockButton from './buttons/BlockButton'
import ChevronForwardIcon from './SVG/ChevronForwardIcon'

const StyledAstroEvents = styled.div``

const AstroEventCard = styled.div`
  position: relative;
  margin: 0;
  overflow: hidden;

  border: 1px solid whitesmoke;
  border-radius: 0;
  padding-bottom: 20px;
  margin: 20px 3% 20px;
  padding: 0 0 0 0;
  max-width: 536px;
  text-align: left;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 10px 0 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5px 0.5px 0px;

  @media (min-width: 720px) {
    margin: 20px auto 20px;
  }

  .astro-card-inner {
    display: flex;

    cursor: pointer;

    .astro-card-image {
      width: 60px;
      min-width: 60px;
      min-height: 60px;
      height: 60px;
      //flex: 1;
      //align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      font-size: 24px;
      position: relative;
      margin-right: 0;
      font-family: 'clever-german', serif;
      background-color: #fff;
      border: 1px solid whitesmoke;
      border-radius: 10px;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        /*background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 80%,
        rgba(255, 255, 255, 1) 100%
      );*/
      }
    }

    .astro-card-text {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      margin-left: 20px;
      width: 75%;
      line-height: 1.3;
      width: 100%;

      h4 {
        font-size: 14px;
        margin: 0 0 4px;
        color: #111;
        font-weight: 700;
        line-height: 1.2;
        width: 100%;
        font-family: 'Open Sans', sans-serif;

        span {
          white-space: nowrap;
        }
      }

      p {
        margin: 0 0 0 0;
        font-size: 14px;
        color: #111;
        color: rgba(51, 51, 51, 0.47);
        font-family: 'Open Sans', sans-serif;
        //color: #adadb3;
        line-height: 1.4;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          white-space: nowrap;
        }
      }
    }

    .astro-card-icon {
      position: relative;
      z-index: 99;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      max-width: 40px;
      min-width: 40px;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      display: flex;
      align-items: center;
      right: 0;
      position: absolute;
      top: 16px;

      svg {
        width: 20px;
        margin: 0 auto;
        display: inline-block;
        fill: rgba(51, 51, 51, 0.47);
        color: rgba(51, 51, 51, 0.47);
      }
    }
  }

  .astro-card-events {
    .astro-card-event {
      display: flex;
      align-items: center;
      padding: 10px 0 0;
      margin: 10px 0 10px 0;
      border-top: 1px solid whitesmoke;
      cursor: pointer;

      .astro-card-event-date {
        min-width: 120px;
        color: rgba(51, 51, 51, 0.47);
        font-size: 13px;

        small {
          margin-top: 2px;
          display: block;
        }
      }
      .astro-card-event-text {
        font-size: 14px;
        width: 100%;

        small {
          margin-top: 2px;
          display: block;
          color: rgba(51, 51, 51, 0.47);
        }
      }

      .astro-event-tell-me-more {
        svg {
          width: 20px;
          height: 20px;
          fill: rgba(51, 51, 51, 0.47);
          color: rgba(51, 51, 51, 0.47);
        }
      }
    }
  }
`

export default function AstrologyEvents({ date, mode = 'day' }) {
  const { t } = useTranslation('common')
  const { getAstroEventExplaination } = useAPI()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const [selection, setSelection] = useState([
    {
      headline: t('astro-events.core-events'),
      icon: '📆',
    },
    {
      headline: t('astro-events.secondary-aspects'),
      icon: '📌',
    },
    {
      headline: t('astro-events.all'),
      icon: '🧷',
    },
  ])
  const [selectedView, setSelectedView] = useState(selection[0])
  const [concjunctions, setConcjunctions] = useState([])
  const [anyMajorAspect, setAnyMajorAspect] = useState([])
  const [astroEventsData, setAstroEventsData] = useState([])
  const [showAllAstrologyEvents, setShowAllAstrologyEvents] = useState(false)

  const [selectedAstroEvent, setSelectedAstroEvent] = useState(null)

  useEffect(() => {
    const fetchMoonData = async () => {
      try {
        if (mode === 'day') {
          const currentDate = date.format('YYYY-MM-DD')
          const response = await fetch(
            `${BASE_URL}/api/astro-events/astro-events.php?date=${currentDate}`,
          )
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          const data = await response.json()

          console.log(data)

          const tmpConjunctions = data.filter(
            (astroEvent) =>
              astroEvent.aspect === 'conjunction' ||
              astroEvent.aspect === 'in' ||
              astroEvent.aspect === 'entered' ||
              astroEvent.aspect === 'enters' ||
              astroEvent.aspect === 'Retrograde' ||
              astroEvent.aspect === 'left' ||
              astroEvent.aspect === 'Direct' ||
              astroEvent.aspect === 'leaves',
          )
          const tmpAnyMajorAspect = data.filter(
            (astroEvent) =>
              astroEvent.aspect === 'trine' ||
              astroEvent.aspect === 'sextile' ||
              astroEvent.aspect === 'opposition' ||
              astroEvent.aspect === 'square',
          )

          setConcjunctions(tmpConjunctions)
          setAnyMajorAspect(tmpAnyMajorAspect)

          setAstroEventsData(data)
        }
        if (mode === 'month') {
          const response = await fetch(
            `${BASE_URL}/api/astro-events/astro-events.php?month=${date.format(
              'MM',
            )}&&year=${date.format('YYYY')}`,
          )
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          const data = await response.json()

          const tmpConjunctions = data.filter(
            (astroEvent) =>
              astroEvent.aspect === 'conjunction' ||
              astroEvent.aspect === 'in' ||
              astroEvent.aspect === 'entered' ||
              astroEvent.aspect === 'enters' ||
              astroEvent.aspect === 'Retrograde' ||
              astroEvent.aspect === 'left' ||
              astroEvent.aspect === 'Direct' ||
              astroEvent.aspect === 'leaves',
          )
          const tmpAnyMajorAspect = data.filter(
            (astroEvent) =>
              astroEvent.aspect === 'trine' ||
              astroEvent.aspect === 'sextile' ||
              astroEvent.aspect === 'opposition' ||
              astroEvent.aspect === 'square',
          )

          setConcjunctions(tmpConjunctions)
          setAnyMajorAspect(tmpAnyMajorAspect)

          setAstroEventsData(data)
        }
      } catch (error) {
        setError(error.message)
      } finally {
        setLoading(false)
      }
    }

    fetchMoonData()
  }, [date])

  const getAstroEvent = async (astroEvent) => {
    console.log(astroEvent)
    let astoEventExplenation = await getAstroEventExplaination(astroEvent)
    console.log(astoEventExplenation)
    setSelectedAstroEvent(astoEventExplenation)
  }

  if (loading) {
    return <div></div>
  }

  if (error) {
    return <div></div>
  }

  function mapSignToEmoji(sign) {
    switch (sign) {
      case 'aries':
        return '♈️'
      case 'taurus':
        return '♉️'
      case 'gemini':
        return '♊️'
      case 'cancer':
        return '♋️'
      case 'leo':
        return '♌️'
      case 'virgo':
        return '♍️'
      case 'libra':
        return '♎️'
      case 'scorpio':
        return '♏️'
      case 'sagittarius':
        return '♐️'
      case 'capricorn':
        return '♑️'
      case 'aquarius':
        return '♒️'
      case 'pisces':
        return '♓️'
      default:
        return ''
    }
  }

  return (
    <StyledAstroEvents>
      <div className="animated fadeIn">
        <SectionHeader
          title={t('astro-events.headline')}
          text={t('astro-events.subheadline')}
        ></SectionHeader>
      </div>

      {/*<TagCloud
        items={selection}
        selectedItem={selectedView}
        onClick={(item) => {
          setSelectedView(item)
        }}
        propertyName="headline"
      ></TagCloud>
      
      circleText={`${dayjs(astroEvent.date).format('DD')}<br />${t(
              dayjs(astroEvent.date).format('MMMM').toLowerCase(),
            ).slice(0, 3)}`}
      
      */}

      {astroEventsData.map((astroEvent, index) => (
        <div key={'astroEvent' + index}>
          <AstroEventCard>
            <div
              className="astro-card-inner"
              onClick={() => {
                getAstroEvent(astroEvent)
              }}
            >
              <div className="astro-card-image">
                <LazyLoadImage
                  effect="blur"
                  wrapperProps={{
                    // If you need to, you can tweak the effect transition using the wrapper style.
                    style: { transitionDelay: '1s' },
                  }}
                  src={`${API_URL}/api/images/watercolor/${
                    '' + astroEvent.planet.toLowerCase().replace(/ /g, '_')
                  }.jpg`} // use normal <img> attributes as props
                />
              </div>
              <div className="astro-card-text">
                <h4>{`${t(astroEvent.planet)} ${
                  astroEvent.currentMotion == null ? '' : ' (' + t(astroEvent.currentMotion) + ')'
                }`}</h4>
                <p>{`${t('in-zodiac')}: ${t(astroEvent.currentZodiac)}`}</p>
              </div>
              <div className="astro-card-icon">
                <ChevronForwardIcon />
              </div>
            </div>
            <div className="astro-card-events">
              {astroEvent.upcomingEvents.map((upcomingEvents, index) => (
                <div
                  key={'astroEventUpcoming' + index}
                  className="astro-card-event"
                  onClick={() => {
                    getAstroEvent(astroEvent, upcomingEvents)
                  }}
                >
                  <div className="astro-card-event-date">
                    {`${dayjs(upcomingEvents.date).format('DD')}. ${t(
                      dayjs(upcomingEvents.date).format('MMMM').toLowerCase(),
                    ).slice(0, 3)} ${dayjs(upcomingEvents.date).format('YYYY')}`}
                    <small>{`${dayjs(upcomingEvents.date).format('HH:mm')}`}</small>
                  </div>
                  <div className="astro-card-event-text">
                    {`${t(upcomingEvents.event.toLowerCase().replace(/ /g, '_'))} ${t(
                      upcomingEvents.secondPlanet,
                    )}`}
                    <small>{`${t(upcomingEvents.zodiac)}`}</small>
                  </div>
                  <div className="astro-event-tell-me-more">
                    <ChevronForwardIcon />
                  </div>
                </div>
              ))}
            </div>
          </AstroEventCard>

          {/*
          <SectionHeader
            title={t(astroEvent.planet)}
            text={`${t(astroEvent.planet)} ${t('in-zodiac')}: ${t(astroEvent.currentZodiac)}`}
            isSmall
          ></SectionHeader>
          {astroEvent.upcomingEvents.map((upcomingEvents, index) => (
            <ContentCard
              key={'upcomingEvents' + index}
              circleText={`${dayjs(upcomingEvents.date).format('DD')}<br />${t(
                dayjs(upcomingEvents.date).format('MMMM').toLowerCase(),
              ).slice(0, 3)}`}
              title={`${t(astroEvent.planet)} ${t(upcomingEvents.aspect)} ${t(
                upcomingEvents.secondPlanet,
              )}`}
              onClick={() => {
                getAstroEvent(
                  `${astroEvent.firstPlanet} ${upcomingEvents.aspect} ${upcomingEvents.secondPlanet}`,
                )
              }}
            ></ContentCard>
          ))} */}
        </div>
      ))}

      {/*
      {selectedView.headline === t('astro-events.core-events') && (
        <>
          {concjunctions.map((astroEvent, index) => (
            <ContentCard
              key={'astroEvent' + index}
              circleText={`${dayjs(astroEvent.date).format('DD')}<br />${t(
                dayjs(astroEvent.date).format('MMMM').toLowerCase(),
              ).slice(0, 3)}`}
              title={`${t(astroEvent.firstPlanet)} ${t(astroEvent.aspect)} ${t(
                astroEvent.secondPlanet,
              )} ${astroEvent.isRetrograde ? t('retrograde') : ''}`}
              onClick={() => {
                getAstroEvent(
                  `${astroEvent.firstPlanet} ${astroEvent.aspect} ${astroEvent.secondPlanet} ${
                    astroEvent.isRetrograde ? ',retrograde' : ''
                  }`,
                )
              }}
            ></ContentCard>
          ))}
        </>
      )}

      
      {selectedView.headline === t('astro-events.secondary-aspects') && (
        <>
          {anyMajorAspect.map((astroEvent, index) => (
            <ContentCard
              key={'astroEvent' + index}
              circleText={`${dayjs(astroEvent.date).format('DD')}<br />${t(
                dayjs(astroEvent.date).format('MMMM').toLowerCase(),
              ).slice(0, 3)}`}
              title={`${t(astroEvent.firstPlanet)} ${t(astroEvent.aspect)} ${t(
                astroEvent.secondPlanet,
              )} ${astroEvent.isRetrograde ? t('retrograde') : ''}`}
              onClick={() => {
                getAstroEvent(
                  `${astroEvent.firstPlanet} ${astroEvent.aspect} ${astroEvent.secondPlanet} ${
                    astroEvent.isRetrograde ? ',retrograde' : ''
                  }`,
                )
              }}
            ></ContentCard>
          ))}
        </>
      )}

      {selectedView.headline === t('astro-events.all') && (
        <>
          {astroEventsData.map((astroEvent, index) => (
            <ContentCard
              key={'astroEvent' + index}
              circleText={`${dayjs(astroEvent.date).format('DD')}<br />${t(
                dayjs(astroEvent.date).format('MMMM').toLowerCase(),
              ).slice(0, 3)}`}
              title={`${t(astroEvent.firstPlanet)} ${t(astroEvent.aspect)} ${t(
                astroEvent.secondPlanet,
              )} ${astroEvent.isRetrograde ? t('retrograde') : ''}`}
              onClick={() => {
                getAstroEvent(
                  `${astroEvent.firstPlanet} ${astroEvent.aspect} ${astroEvent.secondPlanet} ${
                    astroEvent.isRetrograde ? ',retrograde' : ''
                  }`,
                )
              }}
            ></ContentCard>
          ))}
        </>
            )}*/}

      {selectedAstroEvent != null && (
        <Modal
          onClose={() => {
            setSelectedAstroEvent(null)
          }}
        >
          <StoryPage
            title={selectedAstroEvent[0].title}
            text={selectedAstroEvent[0].text}
            handleClose={() => {
              setSelectedAstroEvent(null)
            }}
          ></StoryPage>
        </Modal>
      )}
    </StyledAstroEvents>
  )
}
