import React from 'react'

import styled from 'styled-components'

const StyledTagCloud = styled.div``

function TagCloud({ items, selectedItem, onClick, propertyName }) {
  return (
    <div className="tag-cloud">
      {items.map((item, index) => (
        <div
          key={index}
          className={`tag ${selectedItem === item ? 'is-active' : ''}`}
          onClick={() => onClick(item)}
        >
          {item.svg && <svg />}
          {item.icon && <>{item.icon}&nbsp;</>}
          {item[propertyName]}
        </div>
      ))}
    </div>
  )
}

export default TagCloud
