export const TASK_GO_TO_HOROSCOPE = 'TASK_GO_TO_HOROSCOPE'
export const TASK_GO_TO_MOONDAY = 'TASK_GO_TO_MOONDAY'
export const TASK_GO_TO_CHALLENGE = 'TASK_GO_TO_CHALLENGE'
export const TASKS_GO_TO_AFFIRMATION = 'TASKS_GO_TO_AFFIRMATION'
export const TASKS_GO_TO_COACHING = 'TASKS_GO_TO_COACHING'
export const TASKS_GO_TO_MEDITATION = 'TASKS_GO_TO_MEDITATION'
export const TASKS_GO_TO_FUNFACT = 'TASKS_GO_TO_FUNFACT'
export const TASKS_GO_TO_TAROT = 'TASKS_GO_TO_TAROT'
export const TASK_MARK_AS_DONE = 'TASK_MARK_AS_DONE'
export const TASK_GO_TO_GUIDE_PAGE = 'TASK_GO_TO_GUIDE_PAGE'
export const TASK_GO_TO_FACEBOOK = 'TASK_GO_TO_FACEBOOK'
export const TASK_GO_TO_INSTAGRAM = 'TASK_GO_TO_INSTAGRAM'
export const TASK_WRITE_REVIEW = 'TASK_WRITE_REVIEW'
export const TASK_AUDIO_SESSION = 'TASK_AUDIO_SESSION'
export const TASK_SHOW_OFFER = 'TASK_SHOW_OFFER'
