import Modal from 'components/Modal'
import ShareIcon from 'components/SVG/ShareIcon'
import SectionHeader from 'components/SectionHeader'
import StyledSlider from 'components/StyledSlider'
import { BASE_URL } from 'constants/URLS'
import { useAPI } from 'context/APIcontext'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import TarotCardDetail from '../TarotCardDetail'
import SeeYouTomorrow from 'components/SeeYouTomorrow'
import TarotDetailPageForSpread from '../TarotDetailPageForSpread'
import TarotSpread from '../TarotSpread'

const StyledTodaysCard = styled.div`
  .todays-card {
    border: 1px solid #eee;
    border-radius: 10px;
    max-width: 536px;
    margin: 40px 3% 20px;

    @media (min-width: 720px) {
      margin: 40px auto 20px;
    }

    .todays-card-select {
      display: flex;
      gap: 10px;
      align-items: center;
      text-align: center;
      width: 100%;
      justify-content: center;

      .todays-card-select-entry {
        border-radius: 10px;
        //flex: 1;
        height: 40px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        top: -20px;
        margin: 0;
        transition: 0.2s ease-in-out all;
        border: 1px solid #eee;
        background-color: #fff;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.is-selected {
          color: #fff;
          background-color: #c19c7b;
          border: 1px solid #c19c7b;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 4px;
        }

        span {
          font-size: 11px;
          font-weight: 700;
          white-space: nowrap;
          text-transform: uppercase;
        }
      }
    }
  }

  .card-canvas {
    overflow: hidden;
    padding: 20px 5%;

    .card-container {
      position: relative;
      width: 100%;
      min-height: 240px;
    }

    .tag-cloud {
      margin-top: 20px;
    }

    .page-title-big {
      font-family: 'clever-german', serif;
      //font-family: "breathing", sans-serif;
      padding: 0 3% 10px;
      line-height: 1 !important;
      font-size: 16px;
      font-weight: 700 !important;
      color: #111;
      text-align: center;
      position: relative;
      margin: 0 30px 0;
    }

    .page-title-very-big {
      font-family: 'clever-german', serif;
      //font-family: "breathing", sans-serif;
      padding: 0 3% 0;
      line-height: 1 !important;
      font-size: 18px;
      font-weight: 700 !important;
      color: #111;
      text-align: center;
      position: relative;
      margin: 0 20px 0;
    }

    .page-title-small {
      font-size: 14px;
      font-weight: 400;
      font-family: 'Open Sans', sans-serif;
      line-height: 1.4;
      color: #111;
      min-height: 23px;
      text-align: center;
      max-width: 300px;
      margin: 0 auto 20px;
    }

    .page-title-small {
      margin-top: 10px;
    }

    .slick-dots {
      position: static;
      margin-top: 20px;
    }

    .card-title {
      font-weight: 800;
      text-align: center;
      opacity: 0;
      transition: all 1s ease-in-out;
      color: #c19c7b;
      font-family: 'breathing', sans-serif;
      font-size: 22px;
      line-height: 1.2;
      position: relative;
      top: 10px;
      left: 4px;
      margin: 20px 10% 20px;
      display: block;

      &.revealed {
        opacity: 1;
      }
    }

    .lection-of-card-text {
      //font-family: 'clever-german', serif;
      line-height: 1.4 !important;
      font-size: 18px;
      //font-weight: 700 !important;
      color: #111;
      font-family: 'clever-german', serif;
      text-align: center;
      margin: 10px auto 0;
      opacity: 0;
      transition: all 1s ease-in-out;
      max-width: 420px;
      padding: 0 20px;
      padding: 0 40px;
      border-radius: 10px;

      .headline {
        font-size: 10px;
        font-weight: 700;
        margin-bottom: 10px;
        font-family: 'Open Sans', sans-serif;
        text-transform: uppercase;
        color: #c19c7b;
      }

      &.revealed {
        opacity: 1;
      }
    }

    .lection-of-card-keywords {
      margin-top: 20px;
      font-size: 13px;
      text-align: center;
      opacity: 0;
      transition: all 1s ease-in-out;

      &.revealed {
        opacity: 1;
      }
    }

    .flip-card {
      position: absolute;
      background-color: transparent;
      width: 38vw;
      height: 51vw;
      perspective: 1000px;
      transition: all 1s ease-in-out;
      max-width: 100px;
      max-height: 173px;
      transform: translate(-50%, 0);

      &.reversed .flip-card-back {
        transform: rotateX(180deg);
      }
    }

    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
    }

    .flip-card.revealed .flip-card-inner {
      transform: rotateY(180deg);
    }

    /* Position the front and back side */
    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      overflow: hidden;
    }

    .flip-card-front {
      background-color: #bbb;
      color: black;
    }

    /* Style the back side */
    .flip-card-back {
      color: white;
      transform: rotateY(180deg);
      transition: all 1s ease-in-out;
    }

    .task {
      .task-image {
        background-size: 50% 90%;
        background-repeat: no-repeat;

        &:after {
          display: none;
        }
      }
    }
  }

  .tell-me-more {
    color: #c19c7b;
    font-family: 'clever-german', serif;
    font-size: 16px;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.3s;
    text-align: center;
    margin: 20px 0 20px;

    &:hover {
      color: #000;

      svg {
        fill: #000;
      }
    }

    svg {
      transition: all 0.3s;
      width: 20px;
      height: 20px;
      fill: #c19c7b;
      margin-right: 5px;
    }
  }

  .tap-on-card-hint {
    text-align: center;
    font-size: 12px;
    text-align: center;
  }
`

export default function TodaysCard({ tarotCards, onCardReveal }) {
  const { t } = useTranslation('common')

  const { getTarotSpreads, updateTarotSpread, isPremium, updateCounter } = useAPI()

  const [allSpreads, setAllSpreads] = useState(null)
  const [readingIsDone, setReadingIsDone] = useState(false)
  const [selectedSpread, setSelectedSpread] = useState(null)
  const [currentSpread, setCurrentSpread] = useState(null)
  const [selectedCard, setSelectedCard] = useState(null)
  const [clickedIndex, setClickedIndex] = useState(null)
  const [pickCardOverlayOpened, setPickCardOverlayOpened] = useState(false)
  const [isPickCards, setIsPickCards] = useState(false)
  const [view, setView] = useState('lesson')

  useEffect(() => {
    getSpreads()
  }, [])

  useEffect(() => {
    getSpreads()
  }, [updateCounter])

  function getSpreads(selectedSpread = 'daily') {
    setCurrentSpread(null)
    getTarotSpreads().then((spreads) => {
      const filteredSpreads = spreads.filter(
        (spread) => spread.id === 'daily' || spread.id === 'single',
      )
      setAllSpreads(filteredSpreads)
      prepareSpread(spreads.find((spread) => spread.id === selectedSpread))
    })
  }

  function prepareSpread(spread) {
    const shuffledTarotCards = shuffle([...tarotCards])

    // Assign cards to spread positions without repetition
    spread.cards.forEach((element, index) => {
      if (!element.isRevealed) {
        element.isRevealed = false
        element.reversed = Math.random() >= 0.5
        element.selectedCard = shuffledTarotCards[index]

        // Remove the assigned card from the shuffledTarotCards array
        shuffledTarotCards.splice(index, 1) // Remove the card at the current index
      }
    })

    if (spread.nextCardToReveal === undefined) {
      spread.nextCardToReveal = 0
    }

    if (spread.nextCardToReveal + 1 >= spread.cards.length) {
      setReadingIsDone(true)
    }

    setCurrentSpread({ ...spread })
  }

  function resetSpread(reversedAllowed) {
    const shuffledTarotCards = shuffle([...tarotCards])

    // Assign cards to spread positions without repetition
    spread.cards.forEach((element, index) => {
      element.isRevealed = false
      element.reversed = reversedAllowed ? Math.random() >= 0.5 : false
      element.selectedCard = shuffledTarotCards[index]
    })

    spread.nextCardToReveal = 0

    setCurrentSpread({ ...spread })
  }

  function pickCard(clickedIndex) {
    const cardToUpdate = currentSpread.cards[clickedIndex]

    if (cardToUpdate.isRevealed) {
      setSelectedCard(cardToUpdate.selectedCard)
      setClickedIndex(clickedIndex)
    } else {
      if (isPickCards) {
        setPickCardOverlayOpened(true)
      } else {
        releavedCard(clickedIndex)
      }
    }
  }

  function releavedCard(clickedIndex) {
    // Check if the clicked card is the next to be revealed
    if (clickedIndex === currentSpread.nextCardToReveal) {
      const updatedCards = currentSpread.cards.map((card, index) => {
        if (index === clickedIndex) {
          const updatedCard = { ...card, isRevealed: true }
          if (updatedCard.reversed) {
            updatedCard.reversedClass = true
          } else {
            updatedCard.reversedClass = false
          }
          return updatedCard
        }
        return card
      })

      if (currentSpread.nextCardToReveal + 1 >= currentSpread.cards.length) {
        setReadingIsDone(true)
      }

      setCurrentSpread({
        ...currentSpread,
        cards: updatedCards,
        nextCardToReveal: currentSpread.nextCardToReveal + 1,
        isDone: currentSpread.nextCardToReveal + 1 >= currentSpread.cards.length,
        readingDone: currentSpread.nextCardToReveal + 1 >= currentSpread.cards.length,
      })

      setTimeout(() => {
        updateTarotSpread({
          ...currentSpread,
          cards: updatedCards,
          nextCardToReveal: currentSpread.nextCardToReveal + 1,
          isDone: currentSpread.nextCardToReveal + 1 >= currentSpread.cards.length,
          readingDone: currentSpread.nextCardToReveal + 1 >= currentSpread.cards.length,
        })
        onCardReveal()
      }, 500)
    }
  }

  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
  }

  var settings = {
    dots: true,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    //variableWidth: true,
    swipeToSlide: true,
    spacing: 0,
  }

  return (
    <StyledTodaysCard>
      <div className="todays-card animated fadeIn">
        {allSpreads && currentSpread && (
          <div className="todays-card-select">
            {allSpreads.map((spread) => (
              <div
                key={spread.id}
                className={`todays-card-select-entry ${
                  currentSpread.id === spread.id ? 'is-selected' : ''
                }`}
                onClick={() => {
                  getSpreads(spread.id)
                }}
              >
                <img src={`${BASE_URL}/react/assets/img/spreads/${spread.image}`} alt="" />
                <span>{t(`spreads.spreads.${spread.id}.title`)}</span>
              </div>
            ))}
          </div>
        )}

        {currentSpread && (
          <>
            <div className="card-canvas animation animated fadeIn">
              {currentSpread && (
                <SectionHeader
                  noSpacing
                  title={t('spreads.spreads.' + currentSpread.id + '.title')}
                  text={t('spreads.spreads.' + currentSpread.id + '.description')}
                ></SectionHeader>
              )}

              <div
                className="card-container"
                style={{ minHeight: currentSpread.cards.length === 1 ? '180px' : '240px' }}
              >
                {currentSpread.cards.map((card, cardIndex) => (
                  <div
                    key={`tarot-today-card-${cardIndex}`}
                    className={`flip-card ${card.isRevealed ? 'revealed' : ''} ${
                      card.reversed ? 'reversed' : ''
                    }`}
                    onClick={() => pickCard(cardIndex)}
                    style={{
                      left: card.position.x,
                      top:
                        (currentSpread.cards.length > 1 && cardIndex === 0) || cardIndex === 2
                          ? '40px'
                          : '0',
                      transform: card.position.rotate || '',
                    }}
                  >
                    <div className="flip-card-inner">
                      <div className="flip-card-front">
                        <img
                          src={`${BASE_URL}/api/images/tarotcards/back.jpg`}
                          alt={`Card ${cardIndex}`}
                        />
                      </div>
                      <div className="flip-card-back">
                        {card.selectedCard && (
                          <img
                            src={`${BASE_URL}/api/images/tarotcards/${card.selectedCard.image}.jpg`}
                            alt={`Card ${cardIndex}`}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="tap-on-card-hint">{t('spreads.spread-card-hint')}</div>

              <div
                className="tell-me-more"
                onClick={() => {
                  setSelectedSpread(currentSpread)
                }}
              >
                <ShareIcon />
                {t('tell-me-more')}
              </div>

              {/*
              <StyledSlider {...settings}>
                {currentSpread.cards.map((card, cardIndex) => (
                  <div className="page-title" key={`listview-${cardIndex}`}>
                    <div className="page-title-very-big">
                      {`${cardIndex + 1}. ${t('spreads.card')}: ${t(
                        `spreads.spreads.${currentSpread.id}.cards.${cardIndex}.name`,
                      )}`}
                      :
                    </div>

                    <div className="page-title-small">
                      {t(`spreads.spreads.${currentSpread.id}.cards.${cardIndex}.description`)}
                    </div>

                    <div
                      className={`flip-card ${card.isRevealed ? 'revealed' : ''} ${
                        card.reversed ? 'reversed' : ''
                      }`}
                      onClick={() => pickCard(cardIndex)}
                    >
                      <div className="flip-card-inner">
                        <div className="flip-card-front">
                          <img
                            src={`${BASE_URL}/api/images/tarotcards/back.png`}
                            alt={`Card ${cardIndex}`}
                          />
                        </div>
                        <div className="flip-card-back">
                          {card.selectedCard && (
                            <img
                              src={`${BASE_URL}/api/images/tarotcards/${card.selectedCard.image}.png`}
                              alt={`Card ${cardIndex}`}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    {card.isRevealed && (
                      <>
                        <div className={`card-title ${card.isRevealed ? 'revealed' : ''}`}>
                          {card.reversed
                            ? card.selectedCard.titleReversed
                            : card.selectedCard.title}
                        </div>
                        {view === 'lesson' && (
                          <div
                            className={`lection-of-card-text ${card.isRevealed ? 'revealed' : ''}`}
                          >
                            <div className="headline">{t('lesson')}</div>
                            {!card.reversed
                              ? card.selectedCard.lesson
                              : card.selectedCard.lessonReversed}
                          </div>
                        )}
                        {view === 'affirmation' && (
                          <div
                            className={`lection-of-card-text ${card.isRevealed ? 'revealed' : ''}`}
                          >
                            <div className="headline">{t('affirmation-of-this-card')}</div>
                            {!card.reversed
                              ? card.selectedCard.affirmation
                              : card.selectedCard.affirmationReversed}
                          </div>
                        )}
                        {view === 'keywords' && (
                          <div
                            className={`lection-of-card-text ${card.isRevealed ? 'revealed' : ''}`}
                          >
                            <div className="headline">{t('keywords')}</div>
                            {!card.reversed
                              ? card.selectedCard.keywords
                              : card.selectedCard.keywordReversed}
                          </div>
                        )}
                        {view === 'description' && (
                          <div
                            className={`lection-of-card-text ${card.isRevealed ? 'revealed' : ''}`}
                          >
                            {!card.reversed
                              ? card.selectedCard.shortNormalDescription
                              : card.selectedCard.shortReversedDescription}
                          </div>
                        )}
                        {view === 'nummerlogie' && (
                          <div
                            className={`lection-of-card-text ${card.isRevealed ? 'revealed' : ''}`}
                          >
                            {!card.reversed
                              ? card.selectedCard.nummerlogie
                              : card.selectedCard.nummerlogie}
                          </div>
                        )}
                        {view === 'astrology' && (
                          <div
                            className={`lection-of-card-text ${card.isRevealed ? 'revealed' : ''}`}
                          >
                            {!card.reversed
                              ? card.selectedCard.astrologie
                              : card.selectedCard.astrologie}
                          </div>
                        )}
                        {view === 'yes-or-no' && (
                          <div
                            className={`lection-of-card-text ${card.isRevealed ? 'revealed' : ''}`}
                          >
                            {!card.reversed
                              ? card.selectedCard.yesOrNo
                              : card.selectedCard.yesOrNoReversed}
                          </div>
                        )}
                        {view === 'element' && (
                          <div
                            className={`lection-of-card-text ${card.isRevealed ? 'revealed' : ''}`}
                          >
                            {!card.reversed ? card.selectedCard.element : card.selectedCard.element}
                          </div>
                        )}

                        {/*<div className="tag-cloud hide-to-share">
                          <div
                            className={`tag ${view === 'lesson' ? 'is-active' : ''}`}
                            onClick={() => setView('lesson')}
                          >
                            {t('lesson')}
                          </div>
                          <div
                            className={`tag ${view === 'affirmation' ? 'is-active' : ''}`}
                            onClick={() => setView('affirmation')}
                          >
                            {t('affirmation-of-this-card')}
                          </div>
                          <div
                            className={`tag ${view === 'keywords' ? 'is-active' : ''}`}
                            onClick={() => setView('keywords')}
                          >
                            {t('keywords')}
                          </div>
                          <div
                            className={`tag ${view === 'description' ? 'is-active' : ''}`}
                            onClick={() => setView('description')}
                          >
                            {t('description')}
                          </div>
                          <div
                            className={`tag ${view === 'nummerlogie' ? 'is-active' : ''}`}
                            onClick={() => setView('nummerlogie')}
                          >
                            {t('nummerlogie')}
                          </div>
                          <div
                            className={`tag ${view === 'astrology' ? 'is-active' : ''}`}
                            onClick={() => setView('astrology')}
                          >
                            {t('astrology')}
                          </div>
                          <div
                            className={`tag ${view === 'yes-or-no' ? 'is-active' : ''}`}
                            onClick={() => setView('yes-or-no')}
                          >
                            {t('yes-or-no')}
                          </div>
                          <div
                            className={`tag ${view === 'element' ? 'is-active' : ''}`}
                            onClick={() => setView('element')}
                          >
                            {t('element')}
                          </div>
                        </div>/}
                      </>
                    )}


                    <div
                      className="tell-me-more"
                      onClick={() => {
                        setSelectedSpread(currentSpread);
                      }}
                    >
                      <ShareIcon />
                      {t('tell-me-more')}
                    </div>
                  </div>
                ))}
              </StyledSlider>*/}
            </div>
          </>
        )}
      </div>

      {selectedSpread && (
        <Modal
          onClose={() => {
            setSelectedSpread(null)
          }}
        >
          <TarotSpread
            spread={selectedSpread}
            tarotCards={tarotCards}
            startView={selectedSpread.nextCardToReveal === 0 ? 'info' : 'original'}
          ></TarotSpread>
        </Modal>
      )}

      {selectedCard && (
        <Modal
          onClose={() => {
            setSelectedCard(null)
          }}
        >
          <TarotDetailPageForSpread
            headline={
              currentSpread.cards[clickedIndex].reversed
                ? selectedCard.titleReversed
                : selectedCard.title
            }
            selectedCard={selectedCard}
            reversed={currentSpread.cards[clickedIndex].reversed}
            position={clickedIndex + 1}
            spread={currentSpread}
            spreadTitle={currentSpread.title}
            spreadQuestion={currentSpread.question}
            spreadTopic={currentSpread.cards[clickedIndex].name}
            spreadTopicQuestion={t(
              `spreads.spreads.${currentSpread.id}.cards.${clickedIndex}.description`,
              { lng: 'en' },
            )}
            handleClose={() => setSelectedCard(null)}
          ></TarotDetailPageForSpread>
        </Modal>
      )}
    </StyledTodaysCard>
  )
}
