import React from 'react'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_name.json'
import BlockButton from 'components/buttons/BlockButton'
import FemaleIcon from 'components/SVG/FemaleIcon'
import MaleIcon from 'components/SVG/MaleIcon'
import NonBinaryIcon from 'components/SVG/NonBinaryIcon'
import CheckmarkCircleIcon from 'components/SVG/CheckmarkCircleIcon'

const GenderOption = ({ isSelected, onSelect, Icon, label }) => (
  <div
    className={`icon-select is-primary-button ${isSelected ? 'is-active' : ''}`}
    onClick={onSelect}
  >
    <div className="icon-select-left">
      <div className="icon-select-icon">
        <Icon />
      </div>
      <span>{label}</span>
    </div>
    <div className="icon-select-right">
      {isSelected && (
        <div className="icon-select-checkmark">
          <CheckmarkCircleIcon />
        </div>
      )}
    </div>
  </div>
)

export default function GenderStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  gender,
  setGender,
}) {
  const { t } = useTranslation('common')

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const genderOptions = [
    { value: 'female', Icon: FemaleIcon, label: t('female') },
    { value: 'male', Icon: MaleIcon, label: t('male') },
    { value: 'non-binary', Icon: NonBinaryIcon, label: t('non-binary') },
  ]

  return (
    <div>
      <div className="animated fadeIn">
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>
      <span className="onboarding-headline animated fadeIn">
        {t('welcome-page.step-gender-headline')}
      </span>
      <span className="onboarding-text animated fadeIn">{t('welcome-page.step-gender-text')}</span>

      <div className="icon-select-wrapper animated fadeIn">
        {genderOptions.map(({ value, Icon, label }) => (
          <GenderOption
            key={value}
            isSelected={gender === value}
            onSelect={() => {
              setGender(value)
              scrollToWidget()
              setCurrentStep(currentStep + 1)
            }}
            Icon={Icon}
            label={label}
          />
        ))}
      </div>
    </div>
  )
}
