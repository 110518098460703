import { BASE_URL } from 'constants/URLS'
import React from 'react'

import styled from 'styled-components'
import CheckmarkCircleIcon from './SVG/CheckmarkCircleIcon'

const StyledTaskCard = styled.div`
  position: relative;
  //box-shadow: 0 2px 6px 1px rgba(50, 50, 50, 0.14);
  //box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.12);
  margin: 0;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  display: flex;
  align-items: center;
  //justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
  //margin: 0 3% 10px;
  //padding: 16px 0 16px 20px;
  //box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  //border: 1px solid #eee;
  //padding: 5px 0 5px 0;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &.is-done {
    //border: 1px solid transparent;
    //background-color: transparent;
    //opacity: 0.25 !important;
    border: 1px solid #fff;

    .task-done-text {
      opacity: 1;
    }

    .task-text,
    .task-image {
      opacity: 0.15 !important;
      transition: all 1s ease-in-out;
    }
  }

  .task-done-text {
    position: absolute;
    //color: #c19c7b;
    //font-family: "breathing", sans-serif;
    font-family: 'clever-german', serif;
    z-index: 9;
    opacity: 1;
    font-size: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 0;
    flex-direction: column;
    line-height: 1.2;
    font-weight: 700;

    small {
      font-size: 11px;
      //color: #c19c7b;
      font-weight: 400;
      margin-left: 10px;
      font-family: 'Open Sans', sans-serif;
    }
  }

  .task-image {
    width: 60px;
    min-width: 60px;
    min-height: 60px;
    height: 60px;
    //flex: 1;
    //align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: 50% 50%;
    font-size: 24px;
    position: relative;
    border-radius: 50%;
    margin-right: 0;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      /*background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 80%,
        rgba(255, 255, 255, 1) 100%
      );*/
    }
  }

  .task-number {
    width: 100px;
    height: 100px;
    min-width: 100px;
    background-size: cover;
    background-position: 50% 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    border-radius: 10px;
    border: 2px solid whitesmoke;
    font-family: 'clever-german', serif;
    color: #111;
    font-weight: 700;
    font-size: 28px;
    flex-direction: column;
    text-align: center;
    overflow: hidden;

    .big-number {
      font-size: 16px;
    }

    .small-number {
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      font-weight: 400;
      color: #adadb3;
    }
  }

  .task-checkmark {
    width: 60px;
    font-size: 30px;
    color: #f8f4f0;
    display: flex;
    align-items: center;
  }

  .task-icon {
    position: relative;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 40px;
    min-width: 40px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    display: flex;
    align-items: center;

    svg {
      width: 30px;
      margin: 0 auto;
      display: inline-block;
      fill: #eee;
    }
  }

  .task-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 12px 0;
    margin-left: 20px;
    width: 75%;
    margin-right: 20px;
    line-height: 1.3;
    width: 100%;

    h4 {
      font-size: 14px;
      margin: 0 0 4px;
      color: #111;
      font-weight: 600;
      line-height: 1.2;
      width: 100%;
      font-family: 'Poppins', sans-serif;

      span {
        white-space: nowrap;
      }
    }

    p {
      margin: 2px 0 0 0;
      font-size: 14px;
      color: #111;
      font-family: 'Open Sans', sans-serif;
      //color: #adadb3;
      line-height: 1.4;

      span {
        white-space: nowrap;
      }
    }

    .task-star {
      font-size: 12px;
      font-weight: 700;

      img {
        width: 10px;
      }
    }
  }

  .task-arrow {
    position: absolute;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    max-width: 40px;
    font-size: 20px;
    color: #ddd;
    right: 0;
    width: 20px;
    height: 20px;
  }

  .task-open {
    position: absolute;
    z-index: 99;
    display: flex;
    align-items: center;
    font-size: 11px;
    color: #ddd;
    right: 0;
    background-color: #eeeeef;
    color: #2473f6;
    padding: 6px 12px;
    text-transform: uppercase;
    border-radius: 20px;
    font-weight: 700;
    display: none;
  }
`

export default function TaskCard({ title, text, topic, onClick, isDone, action = '' }) {
  return (
    <StyledTaskCard onClick={onClick} className={`task ${isDone ? 'is-done' : ''} ${action}`}>
      <div className="task-icon">
        <CheckmarkCircleIcon isDone={isDone} />
      </div>
      <div className="task-text">
        <h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
        <p dangerouslySetInnerHTML={{ __html: text }}></p>
      </div>
      {topic != '' && (
        <div
          className="task-image"
          style={{
            backgroundImage: `url(${BASE_URL}/api/images/watercolor/${topic
              .toLowerCase()
              .replace(/ /g, '_')}.jpg)`,
          }}
        ></div>
      )}
      <div className="task-arrow">
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </div>
    </StyledTaskCard>
  )
}
