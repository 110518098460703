import React, { useState, lazy, Suspense, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAPI } from "context/APIcontext";
import PageTitle from "components/PageTitle";
import SectionHeader from "components/SectionHeader";
import BlockButton from "components/buttons/BlockButton";
import LoadingState from "components/LoadingState";
import Modal from "components/Modal";
import ContentCard from "components/ContentCard";
import { API_URL, BIRTHCHART_API, CHART_EXPLANATION_API } from "constants/URLS";
import * as animationData from "assets/lottie/animation_onboarding_gender.json";
import Lottie from "react-lottie";
import convertToDecimalCoordinates from "helper/convertToDecimalCoordinates";
import { getLanguage } from "helper/getLanguage";
import {
  calculateDominantElement,
  calculateDominantModality,
  getMoonPhase,
  getMoonSign,
  getRisingSign,
  getSunSign,
} from "helper/astroHelper";
import AstrologyTable from "components/AstrologyTable";
import AstrologyInsights from "components/AstrologyInsights";
import BirthChartDiagram from "components/BirthChartDiagram";
import { useCoachingOptions } from "constants/COACHING_OPTIONS";
import dayjs from "dayjs";
import ChevronForwardIcon from "components/SVG/ChevronForwardIcon";
import BirthchartPage from "pages/BirthchartPage/BirthchartPage";
import StoryPage from "pages/StoryPage/StoryPage";
import { StyledProfilePage } from "components/ProfilePage/StyledProfilePage";

export default function FriendDetailPage({ selectedFriend }) {
  const { t } = useTranslation("common");
  const {
    deleteTasks,
    userLoggedIn,
    setUserLoggedIn,
    userData,
    saveUserData,
    isPremium,
  } = useAPI();

  const [isLoading, setIsLoading] = useState(false);
  const [birthChart, setBirthChart] = useState(null);
  const [dominantElement, setDominantElement] = useState(null);
  const [dominantModality, setDominantModality] = useState(null);
  const [moonPhase, setMoonPhase] = useState(null);
  const [risingSign, setRisingSign] = useState(null);
  const [sunSign, setSunSign] = useState(null);
  const [moonSign, setMoonSign] = useState(null);

  const [selectedExplanation, setSelectedExplanation] = useState(null);
  const [showBirthChartDetail, setShowBirthChartDetail] = useState(false);

  useEffect(() => {
    if (selectedFriend && selectedFriend.location) {
      getBirthchart(selectedFriend);
    }
  }, []);

  const getBirthchart = async (profile) => {
    console.log(profile);
    const cords = convertToDecimalCoordinates(profile);

    let brithChartAPI = `${BIRTHCHART_API}?language=${getLanguage()}&day=${
      profile.birthDay
    }&month=${profile.birthMonth}&year=${profile.birthYear}&minutes=${
      profile.birthMinute || "00"
    }&hours=${profile.birthHour || "00"}&lat=${cords.decimalLatitude}&lang=${
      cords.decimalLongitude
    }`;

    try {
      const response = await fetch(brithChartAPI);
      const data = await response.json();

      const simpleBirthchart = {
        objects: { ...data.birthchart.objects },
        weightings: { ...data.birthchart.weightings },
        houses: { ...data.birthchart.houses },
        moon_phase: { ...data.birthchart.moon_phase },
      };

      setDominantElement(
        calculateDominantElement(simpleBirthchart.weightings.elements),
      );
      setDominantModality(
        calculateDominantModality(simpleBirthchart.weightings.modalities),
      );
      setMoonPhase(getMoonPhase(simpleBirthchart.moon_phase));
      setRisingSign(getRisingSign(simpleBirthchart.objects));
      setSunSign(getSunSign(simpleBirthchart.objects));
      setMoonSign(getMoonSign(simpleBirthchart.objects));

      setBirthChart(simpleBirthchart);
    } catch (error) {
      return [];
    }
  };

  async function getStory(title) {
    const explanations = await getBirthChartExplanations([title]);
    const explanation = explanations[0];
    setSelectedExplanation(explanation);
  }

  function getBirthChartExplanations(titles) {
    setIsLoading(true);

    let requests = titles.map((title) => {
      let birthChartAPI = `${CHART_EXPLANATION_API}?titles[]=${encodeURIComponent(
        title,
      )}&chartType=birthchart&isMe=true&language=${getLanguage()}`;
      return fetch(birthChartAPI).then((response) => response.json());
    });

    return Promise.all(requests).then((responses) => {
      // Use reduce and concat to merge all response arrays into one big array
      setIsLoading(false);
      return responses.reduce((acc, val) => acc.concat(val), []);
    });
  }

  return (
    <StyledProfilePage>
      <PageTitle
        title={`${selectedFriend.firstName} ${selectedFriend.lastName}`}
        text={`${selectedFriend.birthDay}. ${t(dayjs(`${selectedFriend.birthYear}-${selectedFriend.birthMonth}-${selectedFriend.birthDay}`).format("MMMM").toLowerCase())} ${selectedFriend.birthYear} - ${t(selectedFriend.zodiacSign)}`}
      />

      {birthChart && (
        <>
          <div className="stats-card-wrapper">
            <div
              className="stats-card"
              onClick={() => {
                getStory(`sun sign is ${sunSign.toLowerCase()}`);
              }}
            >
              <div
                className="stats-card-image"
                style={{
                  backgroundImage: `url(${API_URL}/api/images/watercolor/astrology-${sunSign.toLowerCase()}.jpg)`,
                }}
              ></div>
              <div className="stats-card-text">
                <div className="stats-card-label">{t("sun-sign")}</div>
                <div className="stats-card-value">
                  {t(sunSign.toLowerCase())}
                </div>
              </div>
              <div className="stats-card-icon">
                <ChevronForwardIcon />
              </div>
            </div>
            <div
              className="stats-card"
              onClick={() => {
                getStory(`moon sign is ${moonSign.toLowerCase()}`);
              }}
            >
              <div
                className="stats-card-image"
                style={{
                  backgroundImage: `url(${API_URL}/api/images/watercolor/astrology-${moonSign.toLowerCase()}.jpg)`,
                }}
              ></div>
              <div className="stats-card-text">
                <div className="stats-card-label">{t("moon-sign")}</div>
                <div className="stats-card-value">
                  {t(moonSign.toLowerCase())}
                </div>
              </div>
              <div className="stats-card-icon">
                <ChevronForwardIcon />
              </div>
            </div>
            <div
              className="stats-card"
              onClick={() => {
                getStory(
                  `dominant element is ${dominantElement.toLowerCase()}`,
                );
              }}
            >
              <div
                className="stats-card-image"
                style={{
                  backgroundImage: `url(${API_URL}/api/images/watercolor/astrology-${dominantElement.toLowerCase()}.jpg)`,
                }}
              ></div>
              <div className="stats-card-text">
                <div className="stats-card-label">{t("dominant-element")}</div>
                <div className="stats-card-value">
                  {t(dominantElement.toLowerCase())}
                </div>
              </div>
              <div className="stats-card-icon">
                <ChevronForwardIcon />
              </div>
            </div>
            <div
              className="stats-card"
              onClick={() => {
                getStory(
                  `dominant modality is ${dominantModality.toLowerCase()}`,
                );
              }}
            >
              <div
                className="stats-card-image"
                style={{
                  backgroundImage: `url(${API_URL}/api/images/watercolor/astrology-${dominantModality.toLowerCase()}.jpg)`,
                }}
              ></div>
              <div className="stats-card-text">
                <div className="stats-card-label">{t("dominant-modality")}</div>
                <div className="stats-card-value">
                  {t(dominantModality.toLowerCase())}
                </div>
              </div>
              <div className="stats-card-icon">
                <ChevronForwardIcon />
              </div>
            </div>
            <div
              className="stats-card"
              onClick={() => {
                getStory(`rising sign is ${risingSign.toLowerCase()}`);
              }}
            >
              <div
                className="stats-card-image"
                style={{
                  backgroundImage: `url(${API_URL}/api/images/watercolor/astrology-${risingSign.toLowerCase()}.jpg)`,
                }}
              ></div>
              <div className="stats-card-text">
                <div className="stats-card-label">{t("rising-sign")}</div>
                <div className="stats-card-value">
                  {t(risingSign.toLowerCase())}
                </div>
              </div>
              <div className="stats-card-icon">
                <ChevronForwardIcon />
              </div>
            </div>
            <div
              className="stats-card"
              onClick={() => {
                getStory(`moon phase is ${moonPhase.toLowerCase()}`);
              }}
            >
              <div
                className="stats-card-image"
                style={{
                  backgroundImage: `url(${API_URL}/api/images/watercolor/astrology-${moonPhase.toLowerCase().replace(/ /g, "-")}.jpg)`,
                }}
              ></div>
              <div className="stats-card-text">
                <div className="stats-card-label">{t("moon-phase")}</div>
                <div className="stats-card-value">
                  {t(moonPhase.toLowerCase())}
                </div>
              </div>
              <div className="stats-card-icon">
                <ChevronForwardIcon />
              </div>
            </div>
          </div>
        </>
      )}

      <div className="astrology-profile"></div>
      <SectionHeader title={t("detailed-insights")}></SectionHeader>
      <div className="">
        <ContentCard
          image={`${API_URL}/api/images/watercolor/my-birthchart.jpg`}
          title={`${t("birthchart-headline")}`}
          text={`${t("birthchart-subheadline")}`}
          onClick={() => {
            if (userLoggedIn && isPremium) {
              setShowBirthChartDetail(selectedFriend);
            } else {
              setShowOnboarding(true);
            }
          }}
          isLocked={!userLoggedIn || !isPremium}
        ></ContentCard>
      </div>

      {isLoading && (
        <Modal
          onClose={() => {
            setIsLoading(false);
          }}
        >
          <LoadingState
            title={t("article-page.transit-loading-headline")}
            text={t("article-page.transit-loading-text")}
          ></LoadingState>
        </Modal>
      )}

      {selectedExplanation && (
        <Modal
          onClose={() => {
            setSelectedExplanation(null);
          }}
        >
          <StoryPage
            title={selectedExplanation.title}
            text={selectedExplanation.text}
            handleClose={() => {
              setSelectedExplanation(null);
            }}
          ></StoryPage>
        </Modal>
      )}

      {showBirthChartDetail && (
        <Modal
          onClose={() => {
            setShowBirthChartDetail(null);
          }}
        >
          <BirthchartPage
            selectedUserData={showBirthChartDetail}
            goBack={() => {
              setShowBirthChartDetail(null);
            }}
          ></BirthchartPage>
        </Modal>
      )}
    </StyledProfilePage>
  );
}
