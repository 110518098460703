import React, { useState } from 'react'
import { useTranslation } from 'react-i18next' // Import useTranslation hook
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_benefits.json'
import BlockButton from 'components/buttons/BlockButton'
import ReviewCardSimple from 'components/ReviewCardSimple'
import { BASE_URL } from 'constants/URLS'
import styled from 'styled-components'

const StyledAstrologyBlueprintStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .app-preview {
    width: 94%;
    max-width: 343px;
    margin: 80px 3% 0;
    overflow: hidden;
    border-radius: 10px;

    img {
      object-fit: cover;
      width: 100%;
      height: 250px;
    }
  }
`

export default function AstrologyBlueprintStep({ currentStep, setCurrentStep, scrollToWidget }) {
  const { t } = useTranslation('common')

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  })

  return (
    <StyledAstrologyBlueprintStep>
      <div className="app-preview">
        <img src={`${BASE_URL}/images/Frame_1214134129_1.png`} alt="" />
      </div>

      <span className="onboarding-headline animated fadeIn">
        {t('welcome-page.step-astrology-blueprint-headline')}
      </span>

      <span className="onboarding-text animated fadeIn">
        {t('welcome-page.step-astrology-blueprint-text')}
      </span>

      <BlockButton
        isBottom
        text={t('continue')}
        onClick={() => {
          scrollToWidget()
          setCurrentStep(currentStep + 1)
        }}
      ></BlockButton>
    </StyledAstrologyBlueprintStep>
  )
}
