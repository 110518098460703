// Imports for necessary libraries (React, useEffect, etc.)
import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as d3 from 'd3'

const DiagramWrapper = styled.div`
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  position: relative;
  max-width: 600px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;

  svg {
    max-width: 100%;
    height: auto;
    overflow: visible;
  }
`

export default function BirthChartDiagram({ birthChart }) {
  const svgRef = useRef(null)

  useEffect(() => {
    if (birthChart) {
      const width = 600
      const height = 600
      const radius = Math.min(width, height) / 2

      const zodiacSigns = ['♈', '♉', '♊', '♋', '♌', '♍', '♎', '♏', '♐', '♑', '♒', '♓']

      const planetSymbols = ['☉', '☽', '♂', '♀', '☿', '♃', '♄', '♅', '♆', '♇']

      const svg = d3
        .select(svgRef.current)
        .attr('viewBox', `0 0 ${width} ${height}`)
        .attr('preserveAspectRatio', 'xMidYMid meet')
        .append('g')
        .attr('transform', `translate(${width / 2}, ${height / 2})`)

      // Outer zodiac ring
      svg
        .append('circle')
        .attr('r', radius - 20)
        .attr('fill', 'none')
        .attr('stroke', '#000')
        .attr('stroke-width', 2)

      // Add zodiac sectors
      const angleStep = (2 * Math.PI) / 12
      zodiacSigns.forEach((sign, index) => {
        const startAngle = index * angleStep
        const endAngle = startAngle + angleStep

        const arc = d3
          .arc()
          .innerRadius(radius - 100)
          .outerRadius(radius - 20)
          .startAngle(startAngle)
          .endAngle(endAngle)

        svg
          .append('path')
          .attr('d', arc)
          .attr('fill', index % 2 === 0 ? '#f0f0f0' : '#fff')
          .attr('stroke', '#ddd')
          .attr('stroke-width', 0.5)

        // Add zodiac signs
        const angle = startAngle + angleStep / 2 - Math.PI / 2
        const x = Math.cos(angle) * (radius - 60)
        const y = Math.sin(angle) * (radius - 60)

        svg
          .append('text')
          .attr('x', x)
          .attr('y', y)
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'middle')
          .attr('font-size', '18px')
          .attr('fill', '#000')
          .style('font-family', 'Arial, sans-serif')
          .text(sign)
      })

      // Middle ring for planet symbols
      planetSymbols.forEach((symbol, index) => {
        const angle = index * angleStep - Math.PI / 2
        const x = Math.cos(angle) * (radius - 120)
        const y = Math.sin(angle) * (radius - 120)

        svg
          .append('text')
          .attr('x', x)
          .attr('y', y)
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'middle')
          .attr('font-size', '16px')
          .attr('fill', '#000')
          .style('font-family', 'Arial, sans-serif')
          .text(symbol)
      })

      return () => {
        d3.select(svgRef.current).selectAll('*').remove()
      }
    }
  }, [birthChart])

  return (
    <DiagramWrapper>
      <svg ref={svgRef}></svg>
    </DiagramWrapper>
  )
}

BirthChartDiagram.propTypes = {
  birthChart: PropTypes.object.isRequired,
}
