import React, { useState, useEffect, useRef } from "react";
import * as dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { getZodiacSign } from "helper/getZodiac";
import { useAPI } from "context/APIcontext";
import styled from "styled-components";
import FriendsNameStep from "./steps/FriendsNameStep";
import FriendsGenderStep from "./steps/FriendsGenderStep";
import FriendsBornStep from "./steps/FriendsBornStep";
import FriendsBirthTimeStep from "./steps/FriendsBirthTimeStep";
import FriendsBirthdayDateStep from "./steps/FriendsBirthdayDateStep";
import ArrowBack from "components/SVG/ArrowBack";
import FriendAdded from "./steps/FriendAdded";

const StyledAddFriend = styled.div`
  margin: 0 auto;
  min-height: 90vh;
  padding: 0;
  width: 100%;

  .container-fullwidth {
    width: 100%;
  }

  .go-back {
    position: fixed;
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9999;
    box-shadow: #cccccc 0 0 16px;
    transition: all 0.3s;

    svg {
      height: 20px;
      width: 20px;
      fill: #111;
    }

    &:hover {
      transform: rotate(360deg);
    }

    @media (min-width: 720px) {
      width: 60px;
      height: 60px;
      top: 5%;
      left: 5%;

      svg {
        height: 30px;
        width: 30px;
      }
    }
  }

  .onboarding-headline {
    font-weight: 700;
    display: block;
    font-family: "clever-german", serif;
    font-size: 28px;
    margin: 30px 10% 20px;
    color: #111;
    text-align: center;
    line-height: 1.2;
  }

  .onboarding-smallline {
    font-weight: 700;
    display: block;
    font-family: "clever-german", serif;
    font-size: 27px;
    margin: 20px 10% 20px;
    color: #111;
    text-align: center;
    line-height: 1.2;
  }

  .onboarding-text {
    font-family: "Open Sans", sans-serif;
    display: block;
    text-align: center;
    margin: 0 3% 20px;
    max-width: 520px;
    font-size: 16px;
  }

  .onboarding-list {
    list-style-position: outside;
  }

  .onboarding-review-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .story-text {
    color: rgb(46, 59, 75);
    font-family: now, sans-serif;
    font-size: 16px;
    text-align: center;
    line-height: 1.4;
    margin: 20px 10% 10px;
    width: 90%;
    max-width: 740px;
    z-index: 99;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: 375px) {
      font-size: 14px;
    }

    .headline {
      font-family: "clever-german", serif;
      font-size: 18px;
      line-height: 1.2;
      font-weight: 700 !important;
      color: #111;
      color: #c19c7b;
      margin: 0 0 10px;
    }

    b {
      color: #c19c7b;
      //font-family: 'breathing', sans-serif;
      font-size: 30px;
      letter-spacing: -1px;
      margin-bottom: 20px;
      display: block;

      @media only screen and (max-width: 375px) {
        font-size: 24px;
      }
    }

    h6 {
      font-size: 12px;
    }

    .tell-me-more {
      margin-top: 20px;
      background-color: #fff;
      border-radius: 10px;
      font-size: 14px;
      display: inline-block;
      padding: 10px 20px;
      color: #7f8aa5;
      border-left: 1px solid #eee;
    }
  }
`;

export default function AddFriend({ onFriendAdded }) {
  const { t } = useTranslation("common");
  const myRef = useRef(null);

  const { addFriend, userLoggedIn, isPremium } = useAPI();

  const [currentStep, setCurrentStep] = useState(0);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState(null);
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("0");
  const [year, setYear] = useState("");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [timeknown, setTimeKnown] = useState(false);

  function scrollToWidget() {
    myRef.current.scrollIntoView();
  }

  const saveFriend = async () => {
    const zodiacSign = getZodiacSign(Number(day), Number(month));
    const friendsData = {
      id: Date.now(),
      isMe: false,
      firstName: firstName,
      lastName: lastName,
      birthYear: year,
      birthMonth: month,
      birthDay: day,
      birthHour: hours,
      birthMinute: minutes,
      location: location,
      knowExactTime: timeknown,
      zodiacSign: zodiacSign,
    };

    addFriend(friendsData);
    onFriendAdded();
  };

  const steps = [
    <FriendsNameStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      firstName={firstName}
      setFirstName={(firstName) => {
        console.log(firstName);
        setFirstName(firstName);
      }}
      lastName={lastName}
      setLastName={(lastName) => {
        console.log(lastName);
        setLastName(lastName);
      }}
    />,
    <FriendsGenderStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      gender={gender}
      setGender={setGender}
    />,
    <FriendsBornStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      location={location}
      setLocation={setLocation}
    />,
    <FriendsBirthdayDateStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      day={day}
      setDay={setDay}
      month={month}
      setMonth={setMonth}
      year={year}
      setYear={setYear}
    />,
    <FriendsBirthTimeStep
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      hours={hours}
      setHours={setHours}
      minutes={minutes}
      setMinutes={setMinutes}
      timeknown={timeknown}
      setTimeKnown={setTimeKnown}
    />,
    <FriendAdded
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      scrollToWidget={scrollToWidget}
      handleFinishOnboarding={saveFriend}
    />,
  ];

  return (
    <StyledAddFriend ref={myRef}>
      {currentStep > 0 && (
        <div
          className="go-back"
          onClick={() => {
            setCurrentStep((prev) => Math.max(0, prev - 1));
          }}
        >
          <ArrowBack />
        </div>
      )}
      {steps[currentStep]}
    </StyledAddFriend>
  );
}
