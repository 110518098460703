import React from 'react'
import styled from 'styled-components'

const StyledBlockButton = styled.div`
  background-color: #c19c7b;
  border: 2px solid #c19c7b;
  color: #fff;
  text-align: center;
  padding: 12px 10px;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  font-family: 'clever-german', serif;
  font-size: 17px;
  transition: all 0.5s ease;
  max-width: 536px;
  margin: 20px 3%;

  @media (min-width: 720px) {
    margin: 20px auto;
  }

  .button-icon svg {
    width: 20px;
    height: 20px;
    fill: #c19c7b;
    margin-right: 10px;
  }

  &.is-bottom {
    left: 50%;
    position: fixed;
    bottom: 30px;
    margin-bottom: var(--ion-safe-area-bottom, 0);
    margin: 0 auto;
    width: 94%;
    max-width: 536px;
    transform: translate(-50%, 0);
    z-index: 30;
    background-color: #c19c7b;
    border-color: #c19c7b;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5px 0.5px 0px;
    //background-color: #181c27;
    //border-color: #181c27;

    svg {
      fill: #fff;
    }

    @media (min-width: 768px) {
    }
  }

  &.is-bottom-second {
    left: 50%;
    position: fixed;
    bottom: 100px;
    margin-bottom: var(--ion-safe-area-bottom, 0);
    margin: 0 auto;
    width: 94%;
    max-width: 536px;
    transform: translate(-50%, 0);
    z-index: 30;

    svg {
      fill: #fff;
    }

    @media (min-width: 768px) {
    }
  }

  &.is-disabled {
    background-color: #e0e0e0;
    border: 1px solid #e0e0e0;
    color: #fff;
    cursor: not-allowed;
  }

  &.no-border {
    border: 0 none;
    background-color: transparent !important;
  }

  &.full-width {
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
  }

  &.is-solid {
    background-color: #c19c7b;
    color: #fff;

    svg {
      fill: #fff;
    }
  }

  &.no-margin {
    margin: 0;
  }

  &.is-outline {
    background-color: transparent;
    color: #c19c7b;

    svg {
      fill: #c19c7b;
    }
  }
`

export default function BlockButton({
  text,
  disabled,
  onClick,
  isOutlined = false,
  isBottom = false,
  isSolid = false,
  noBorder = false,
  isBottomSecond = false,
  noMargin = false,
  icon = null,
  className = '',
}) {
  function handleOnClick() {
    if (disabled) return
    onClick()
  }

  return (
    <StyledBlockButton
      className={`${disabled ? 'is-disabled' : ''} ${isOutlined ? 'is-outline' : ''} ${
        noMargin ? 'no-margin' : ''
      } ${isBottom ? 'is-bottom' : ''}${isBottomSecond ? 'is-bottom-second' : ''} ${
        isSolid ? 'is-solid' : ''
      } ${noBorder ? 'no-border' : ''} ${className}`}
      onClick={() => {
        handleOnClick()
      }}
    >
      <span className="button-icon">{icon}</span>
      {text}
    </StyledBlockButton>
  )
}
