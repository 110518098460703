import dayjs from 'dayjs'
import React from 'react'
import styled from 'styled-components'

const StyledReviewCardSimple = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
  border-radius: 0.75rem;
  background: #f0f3fb;
  margin: 0 3% 20px;

  &.has-image {
    max-width: 343px;
  }

  video {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
  }

  .review-simple-rating {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;

    p {
      color: rgb(128, 128, 128);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0;

      span {
        padding-left: 6px;
      }
    }
  }

  .review-simple-headline {
    display: flex;

    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;

    p {
      margin: 0;
      color: #000;
      text-align: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: -0.011rem;
      text-align: left;
    }
  }

  .review-simple-text {
    margin: 0;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    line-height: 150%;
    letter-spacing: -0.011rem;
    text-align: left;
  }
`

export default function ReviewCardSimple({ headline, name, text, image, video }) {
  return (
    <StyledReviewCardSimple className={`${image || video ? 'has-image' : ''}`}>
      {image && <img src={image} alt={name} />}

      {video && (
        <video autoPlay playsInline loop muted>
          <source src={video} type="video/mp4" />
        </video>
      )}

      {!video && !image && (
        <div className="review-simple-rating">
          <p>{dayjs().subtract(5, 'days').format('DD.MM.YYYY')}</p>
          <p>
            <svg width="123" height="20" viewBox="0 0 123 20" fill="none">
              <path d="M20 0H0V20H20V0Z" fill="#00A676"></path>
              <path
                d="M17.2257 7.96912H11.7981L10.1324 2.90625L8.46665 7.96912H3.03906L7.4786 11.0843L5.7496 16.1471L10.1324 13.0095L14.5152 16.1471L12.7829 11.0843L17.2257 7.96912Z"
                fill="#FAFDFA"
              ></path>
              <path d="M45.7148 0H25.7148V20H45.7148V0Z" fill="#00A676"></path>
              <path
                d="M42.6183 7.96912H37.1907L35.525 2.90625L33.8592 7.96912H28.4316L32.8712 11.0843L31.1422 16.1471L35.525 13.0095L39.9078 16.1471L38.1755 11.0843L42.6183 7.96912Z"
                fill="#FAFDFA"
              ></path>
              <path d="M71.4277 0H51.4277V20H71.4277V0Z" fill="#00A676"></path>
              <path
                d="M68.0128 7.96521H62.5853L60.9195 2.90234L59.2538 7.96521H53.8262L58.2657 11.0804L56.5367 16.1432L60.9195 13.0056L65.3023 16.1432L63.57 11.0804L68.0128 7.96521Z"
                fill="#FAFDFA"
              ></path>
              <path d="M97.1426 0H77.1426V20H97.1426V0Z" fill="#00A676"></path>
              <path
                d="M87.1426 13.4792L90.1842 12.7083L91.4551 16.625L87.1426 13.4792ZM94.1426 8.41667H88.7884L87.1426 3.375L85.4967 8.41667H80.1426L84.4759 11.5417L82.8301 16.5833L87.1634 13.4583L89.8301 11.5417L94.1426 8.41667Z"
                fill="white"
              ></path>
              <path d="M97.1426 0H77.1426V20H97.1426V0Z" fill="#00A676"></path>
              <path
                d="M94.423 7.96912H88.9954L87.3297 2.90625L85.6639 7.96912H80.2363L84.6759 11.0843L82.9469 16.1471L87.3297 13.0095L91.7125 16.1471L89.9802 11.0843L94.423 7.96912Z"
                fill="#FAFDFA"
              ></path>
              <path d="M122.857 0H102.857V20H122.857V0Z" fill="#00A676"></path>
              <path
                d="M112.854 13.4792L115.895 12.7083L117.166 16.625L112.854 13.4792ZM119.854 8.41667H114.499L112.854 3.375L111.208 8.41667H105.854L110.187 11.5417L108.541 16.5833L112.874 13.4583L115.541 11.5417L119.854 8.41667Z"
                fill="white"
              ></path>
              <path d="M122.857 0H102.857V20H122.857V0Z" fill="#00A676"></path>
              <path
                d="M120.134 7.96912H114.706L113.041 2.90625L111.375 7.96912H105.947L110.387 11.0843L108.658 16.1471L113.041 13.0095L117.423 16.1471L115.691 11.0843L120.134 7.96912Z"
                fill="#FAFDFA"
              ></path>
            </svg>
          </p>
        </div>
      )}

      <div className="review-simple-headline">
        <p>{headline}</p>
        <p>{name}</p>
      </div>

      <div className="review-simple-text">{text}</div>
    </StyledReviewCardSimple>
  )
}
