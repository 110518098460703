import React from 'react'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_name.json' // Replace with your desired Lottie animation
import BlockButton from 'components/buttons/BlockButton'
import CheckmarkCircleIcon from 'components/SVG/CheckmarkCircleIcon'

const FamiliarityOption = ({ isSelected, onSelect, label, icon }) => (
  <div
    className={`icon-select is-primary-button ${isSelected ? 'is-active' : ''}`}
    onClick={onSelect}
  >
    <div className="icon-select-left">
      <div className="icon-select-icon">{icon}</div>
      <span>{label}</span>
    </div>
    <div className="icon-select-right">
      {isSelected && (
        <div className="icon-select-checkmark">
          <CheckmarkCircleIcon />
        </div>
      )}
    </div>
  </div>
)

export default function AstrologyFamiliarityStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  familiarity,
  setFamiliarity,
}) {
  const { t } = useTranslation('common')

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const familiarityOptions = [
    { value: 'beginner', icon: '🤷‍♀️', label: t('welcome-page.step-familiarity-beginner') },
    { value: 'intermediate', icon: '🤏', label: t('welcome-page.step-familiarity-intermediate') },
    { value: 'expert', icon: '🎓', label: t('welcome-page.step-familiarity-expert') },
  ]

  return (
    <div>
      <div className="animated fadeIn">
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>
      <span className="onboarding-headline animated fadeIn">
        {t('welcome-page.step-familiarity-headline')}
      </span>

      <div className="icon-select-wrapper animated fadeIn">
        {familiarityOptions.map(({ value, label, icon }) => (
          <FamiliarityOption
            key={value}
            isSelected={familiarity === value}
            onSelect={() => {
              setFamiliarity(value)
              scrollToWidget()
              setCurrentStep(currentStep + 1)
            }}
            icon={icon}
            label={label}
          />
        ))}
      </div>
    </div>
  )
}
