import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_benefits.json'
import styled from 'styled-components'
import ReviewCardSimple from 'components/ReviewCardSimple'
import { getZodiacSign } from 'helper/getZodiac'

const StyledGeneratingFirstStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .progress-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }

  .progress-circle {
    transform: rotate(-90deg);
  }

  .progress-background {
    fill: none;
    stroke: #e6e6e6;
    stroke-width: 3;
  }

  .progress-bar {
    fill: none;
    stroke: #c19c7b; /* Adjust this color for your theme */
    stroke-width: 3;
    stroke-linecap: round;
    transition: stroke-dasharray 0.3s ease;
  }

  .progress-text {
    font-family: 'clever-german', serif;
    position: absolute;
    font-size: 32px;
    font-weight: 700;
    color: #c19c7b; /* Match with progress-bar color */
  }

  .info-box {
    margin: 20px 3%;
    text-align: left;
    padding: 20px;
    background: #f0f3fb;
    border-radius: 10px;
    border: 1px solid #e6e6e6;
    max-width: 500px;
    font-size: 18px;

    .info-box-text {
      margin: 0 0 20px;
    }

    ul {
      margin: 0;
      list-style: none;
    }
  }
`

export default function CombinedRelationshipResult({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  day,
  month,
  gender,
}) {
  const { t } = useTranslation('common')
  const [progress, setProgress] = useState(0)
  const [zodiac, setZodiac] = useState('gemini')

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => Math.min(prev + 1, 100)) // Ensure progress doesn't exceed 100
    }, 100) // Increment progress every 50ms

    if (progress === 100) {
      const timeout = setTimeout(() => {
        scrollToWidget()
        setCurrentStep(currentStep + 1) // Move to the next step after a slight delay
      }, 1000) // Allow smooth transition
      return () => clearTimeout(timeout) // Cleanup timeout
    }

    return () => clearInterval(interval) // Cleanup interval on unmount
  }, [progress, currentStep, setCurrentStep, scrollToWidget])

  useEffect(() => {
    const zodiacSign = getZodiacSign(day, month)
    setZodiac(zodiacSign)
  }, [])

  return (
    <StyledGeneratingFirstStep>
      <div className="progress-wrapper">
        <svg className="progress-circle" width="200" height="200" viewBox="0 0 36 36">
          <path
            className="progress-background"
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className="progress-bar"
            strokeDasharray={`${progress}, 100`}
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>
        <div className="progress-text">{`${progress}%`}</div>
      </div>

      <span className="onboarding-headline">
        {t('welcome-page.step-generating-relationship-insights-headline')}
      </span>

      <div className="info-box">
        <div
          className="info-box-text"
          dangerouslySetInnerHTML={{
            __html: t('welcome-page.step-generating-relationship-insights-text'),
          }}
        ></div>
        <ul>
          <li>✅ {t('welcome-page.step-generating-relationship-insights-li-1')}</li>
          <li>✅ {t('welcome-page.step-generating-relationship-insights-li-2')}</li>
          <li>✅ {t('welcome-page.step-generating-relationship-insights-li-3')}</li>
        </ul>
      </div>
    </StyledGeneratingFirstStep>
  )
}
