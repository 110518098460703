// Imports for necessary libraries (React, useState, etc.)
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const InsightsWrapper = styled.div`
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;

  h2 {
    margin-bottom: 16px;
  }

  .insight {
    margin-bottom: 12px;

    p {
      margin: 0;
    }

    strong {
      color: #333;
    }
  }
`

export default function AstrologyInsights({ birthChart }) {
  const [insights, setInsights] = useState({})

  useEffect(() => {
    if (birthChart) {
      const calculatedInsights = {}

      // Life Themes
      const dominantQuadrant = Object.entries(birthChart.weightings.quadrants).reduce(
        (max, [quadrant, objects]) =>
          objects.length > max.count ? { quadrant, count: objects.length } : max,
        { quadrant: null, count: 0 },
      )
      calculatedInsights.lifeThemes = `Your chart is heavily influenced by the ${dominantQuadrant.quadrant} quadrant.`

      // Career Insights
      const mc = Object.values(birthChart.objects).find((obj) => obj.name === 'MC')
      const sun = Object.values(birthChart.objects).find((obj) => obj.name === 'Sun')
      calculatedInsights.careerInsights = mc
        ? `Your career is influenced by your Midheaven (MC) in ${mc.sign.name}.`
        : 'Midheaven data is unavailable.'
      if (sun) {
        calculatedInsights.careerInsights += ` The Sun in ${sun.sign.name} plays a significant role in your ambitions.`
      }

      // Relationship Patterns
      const desc = Object.values(birthChart.objects).find((obj) => obj.name === 'Desc')
      const venus = Object.values(birthChart.objects).find((obj) => obj.name === 'Venus')
      const mars = Object.values(birthChart.objects).find((obj) => obj.name === 'Mars')
      calculatedInsights.relationshipPatterns = desc
        ? `Your relationships are guided by your Descendant in ${desc.sign.name}.`
        : 'Descendant data is unavailable.'
      if (venus) {
        calculatedInsights.relationshipPatterns += ` Venus in ${venus.sign.name} indicates how you express love.`
      }
      if (mars) {
        calculatedInsights.relationshipPatterns += ` Mars in ${mars.sign.name} represents your passion and drive.`
      }

      // Self-Reflection
      const asc = Object.values(birthChart.objects).find((obj) => obj.name === 'Asc')
      const moon = Object.values(birthChart.objects).find((obj) => obj.name === 'Moon')
      calculatedInsights.selfReflection = asc
        ? `Your Ascendant (Rising Sign) in ${asc.sign.name} shapes your outward personality.`
        : 'Ascendant data is unavailable.'
      if (moon) {
        calculatedInsights.selfReflection += ` The Moon in ${moon.sign.name} governs your emotions and inner world.`
      }

      setInsights(calculatedInsights)
    }
  }, [birthChart])

  return (
    <InsightsWrapper>
      <h2>Insights & Summaries</h2>
      <div className="insight">
        <strong>🔍 Life Themes:</strong>
        <p>{insights.lifeThemes}</p>
      </div>
      <div className="insight">
        <strong>🎯 Career Insights:</strong>
        <p>{insights.careerInsights}</p>
      </div>
      <div className="insight">
        <strong>💖 Relationship Patterns:</strong>
        <p>{insights.relationshipPatterns}</p>
      </div>
      <div className="insight">
        <strong>🪞 Self-Reflection:</strong>
        <p>{insights.selfReflection}</p>
      </div>
    </InsightsWrapper>
  )
}

AstrologyInsights.propTypes = {
  birthChart: PropTypes.object.isRequired,
}
