import React, { useState, useEffect } from "react";
import { BASE_URL } from "constants/URLS";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAPI } from "context/APIcontext";
import SectionHeader from "components/SectionHeader";
import BlockButton from "components/buttons/BlockButton";
import AddFriend from "pages/AddFriend/AddFriend";
import Modal from "components/Modal";
import SeeYouTomorrow from "components/SeeYouTomorrow";
import ContentCard from "components/ContentCard";
import PageTitle from "components/PageTitle";
import LoadingState from "components/LoadingState";
import * as animationDataNoFriends from "assets/lottie/animation_onboarding_premium.json";
import OnboardingPage from "pages/OnboardingPage/OnboardingPage";
import Lottie from "react-lottie";
import * as animationData from "assets/lottie/animation_onboarding_benefits.json";
import FriendDetailPage from "pages/FriendDetailPage/FriendDetailPage";

const StyledFriendsPage = styled.div`
  max-width: 536px;
  margin: 0;
  width: 100%;
  min-height: 90vh;

  @media (min-width: 720px) {
    margin: 0 auto 20px;
  }
`;

export default function FriendsPage() {
  const { t } = useTranslation("common");
  const { userData, getFriends, userLoggedIn, removeFriend } = useAPI();

  const [addFriendOverlay, setAddFriendOverlay] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [showSelectedFriend, setShowSelectedFriend] = useState(null);
  const [friends, setFriends] = useState([]);

  useEffect(() => {
    const loadFriends = async () => {
      const fetchedFriends = await getFriends();
      setFriends(fetchedFriends.filter((friend) => !friend.isMe));
    };
    loadFriends();
  }, [getFriends]);

  const handleAddFriend = () => setAddFriendOverlay(true);
  const handleFriendClick = (friend) => {
    userData.userProfile
      ? setShowSelectedFriend(friend)
      : setShowOnboarding(true);
  };

  return (
    <StyledFriendsPage>
      {friends.length > 0 ? (
        <>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={200}
            width={200}
          />
          <PageTitle
            title={t("friends.section-headline")}
            text={t("friends.section-text")}
          />

          {friends.map((friend, index) => (
            <ContentCard
              key={index}
              title={`${friend.firstName} ${friend.lastName || ""}`}
              text={
                friend.birthDay
                  ? `${t("birthdate")}: ${friend.birthDay}.${friend.birthMonth}.${friend.birthYear}`
                  : t("not-registered")
              }
              isLocked={false}
              image={`${BASE_URL}/api/images/watercolor/zodiac-${encodeURIComponent(
                friend.zodiacSign.toLowerCase().replace(/ /g, "_"),
              )}.jpg`}
              onClick={() => handleFriendClick(friend)}
              isWhite
            />
          ))}

          <BlockButton
            isBottomSecond
            onClick={handleAddFriend}
            text={t("friends.add-friend")}
          />
        </>
      ) : userLoggedIn ? (
        <>
          <LoadingState
            title={t("friends-page.no-friends-headline")}
            text={t("friends-page.no-friends-text")}
            animationData={animationDataNoFriends}
            noMargin
          />

          <BlockButton
            isBottomSecond
            onClick={handleAddFriend}
            text={t("friends.add-friend")}
          />
        </>
      ) : (
        <>
          <LoadingState
            title={t("friends-page.login-to-add-friend-headline")}
            text={t("friends-page.login-to-add-friend-text")}
            animationData={animationDataNoFriends}
            noMargin
          />

          <BlockButton
            isBottomSecond
            onClick={() => {
              setShowOnboarding(true);
            }}
            text={t("friends-page.login-to-add-friend-cta")}
          />
        </>
      )}

      {addFriendOverlay && (
        <Modal onClose={() => setAddFriendOverlay(false)}>
          <AddFriend
            onFriendAdded={() => {
              setAddFriendOverlay(false);
              setFriends(getFriends());
            }}
          />
        </Modal>
      )}

      {showSelectedFriend && (
        <Modal onClose={() => setShowSelectedFriend(null)}>
          <FriendDetailPage
            onClose={() => setShowSelectedFriend(null)}
            selectedFriend={showSelectedFriend}
            onRemoveFriend={removeFriend}
          />
        </Modal>
      )}

      {showOnboarding && (
        <Modal onClose={() => setShowOnboarding(false)}>
          <OnboardingPage onClose={() => setShowOnboarding(false)} />
        </Modal>
      )}
    </StyledFriendsPage>
  );
}
