import React, { useState } from 'react'
import { useTranslation } from 'react-i18next' // Import useTranslation hook
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_alert_reminders.json'
import BlockButton from 'components/buttons/BlockButton'

export default function FriendAdded({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  handleFinishOnboarding,
}) {
  const { t } = useTranslation('common')

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  })

  return (
    <div>
      <div className="animated fadeIn">
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isStopped={defaultOptions.isStopped}
          isPaused={defaultOptions.isPaused}
        />
      </div>
      <span className="onboarding-headline animated fadeIn">
        {t('add-friends-page.done-headline')}
      </span>
      <span className="onboarding-text animated fadeIn">{t('add-friends-page.done-text')}</span>

      <BlockButton
        isBottom
        text={t('continue')}
        onClick={() => {
          scrollToWidget()
          handleFinishOnboarding()
        }}
      ></BlockButton>
    </div>
  )
}
