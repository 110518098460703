import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import CheckmarkCircleIcon from 'components/SVG/CheckmarkCircleIcon'
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_name.json'

const StyledCombinedRelationshipStep = styled.div``

const RelationshipOption = ({ isSelected, onSelect, label, icon }) => (
  <div
    className={`icon-select is-primary-button ${isSelected ? 'is-active' : ''}`}
    onClick={onSelect}
  >
    <div className="icon-select-left">
      <div className="icon-select-icon">{icon}</div>
      <span>{label}</span>
    </div>
    {isSelected && (
      <div className="icon-select-right">
        <CheckmarkCircleIcon />
      </div>
    )}
  </div>
)

export default function CombinedRelationshipStep({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  relationshipAnswers,
  setRelationshipAnswers,
}) {
  const { t } = useTranslation('common')
  const [currentQuestion, setCurrentQuestion] = useState(0)

  const questions = [
    {
      key: 'express-love',
      headline: t('welcome-page.relationship-express-love-headline'),
      text: t('welcome-page.relationship-express-love-text'),
      options: [
        { value: 'words', icon: '💬', label: t('welcome-page.relationship-express-love-words') },
        { value: 'time', icon: '⏳', label: t('welcome-page.relationship-express-love-time') },
        { value: 'gifts', icon: '🎁', label: t('welcome-page.relationship-express-love-gifts') },
        {
          value: 'service',
          icon: '🤝',
          label: t('welcome-page.relationship-express-love-service'),
        },
        { value: 'touch', icon: '🤗', label: t('welcome-page.relationship-express-love-touch') },
      ],
    },
    {
      key: 'logic-vs-emotions',
      headline: t('welcome-page.relationship-logic-emotions-headline'),
      text: t('welcome-page.relationship-logic-emotions-text'),
      options: [
        { value: 'logic', icon: '🧠', label: t('welcome-page.relationship-logic') },
        { value: 'emotions', icon: '❤️', label: t('welcome-page.relationship-emotions') },
        { value: 'both', icon: '⚖️', label: t('welcome-page.relationship-both') },
      ],
    },
    {
      key: 'overthink-relationships',
      headline: t('welcome-page.relationship-overthink-headline'),
      text: t('welcome-page.relationship-overthink-text'),
      options: [
        { value: 'always', icon: '🔁', label: t('welcome-page.relationship-always') },
        { value: 'often', icon: '🤔', label: t('welcome-page.relationship-often') },
        { value: 'rarely', icon: '🙃', label: t('welcome-page.relationship-rarely') },
        { value: 'never', icon: '🚫', label: t('welcome-page.relationship-never') },
      ],
    },
    {
      key: 'romantic-partner',
      headline: t('welcome-page.relationship-romantic-partner-headline'),
      text: t('welcome-page.relationship-romantic-partner-text'),
      options: [
        {
          value: 'emotional-security',
          icon: '🛡️',
          label: t('welcome-page.relationship-emotional-security'),
        },
        { value: 'sexual-life', icon: '🔥', label: t('welcome-page.relationship-sexual-life') },
        { value: 'gestures', icon: '💐', label: t('welcome-page.relationship-romantic-gestures') },
        { value: 'humor', icon: '😂', label: t('welcome-page.relationship-humor') },
        { value: 'communication', icon: '📞', label: t('welcome-page.relationship-communication') },
        { value: 'other', icon: '❓', label: t('welcome-page.relationship-other') },
      ],
    },
  ]

  const handleSelect = (value) => {
    setRelationshipAnswers({ ...relationshipAnswers, [questions[currentQuestion].key]: value })
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1)
    } else {
      scrollToWidget()
      setCurrentStep(currentStep + 1)
    }
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const { headline, text, options } = questions[currentQuestion]

  return (
    <StyledCombinedRelationshipStep>
      <div className="animated fadeIn">
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>

      <span className="onboarding-headline animated fadeIn">{headline}</span>
      <span className="onboarding-text animated fadeIn">{text}</span>

      <div className="icon-select-wrapper animated fadeIn">
        {options.map(({ value, label, icon }) => (
          <RelationshipOption
            key={value}
            isSelected={relationshipAnswers[questions[currentQuestion].key] === value}
            onSelect={() => handleSelect(value)}
            label={label}
            icon={icon}
          />
        ))}
      </div>
    </StyledCombinedRelationshipStep>
  )
}
